export interface HeaderTitleConfig {
    titleSearch: string;
    titleSearchResults: string;
    titleSearchMerge: string;
    titleSearchSplit: string;
    titleCreate: string;
    titleUpdate: string;
    titleWorklist: string;
    titleIdentityDetails: string;
    titleMCIHome: string;
    titleMCIPendingTransactions: string;
    titleMCIWorklist: string;
    titleMCIReports: string;
    titleSettingsUserPreferences: string;
}

export const HEADER_TITLES: HeaderTitleConfig = {
    titleSearch: 'SEARCH',
    titleSearchResults: 'SEARCH RESULTS',
    titleSearchMerge: 'MERGE',
    titleSearchSplit: 'SPLIT',
    titleCreate: 'CREATE',
    titleUpdate: 'UPDATE',
    titleWorklist: 'WORKLIST',
    titleIdentityDetails: 'IDENTITY DETAILS',
    titleMCIHome: 'MASTER PERSON INDEX HOME',
    titleMCIPendingTransactions: 'PENDING TRANSACTIONS',
    titleMCIWorklist: 'MY WORK LISTS',
    titleMCIReports: 'REPORTS',
    titleSettingsUserPreferences: 'USER PREFERENCES'
};
