import { Injectable } from '@angular/core';
import { IAddress, IMpiRecord, MpiStorageKey as MpiStorageKey, MpiRecordStatus, GenderType } from './search.config';
import { MatDialog } from '@angular/material/dialog';
import { formatSSN } from '@shared/utility/functions/formatting/format-ssn.function';
import { formatDate, InitialDateStringFormatTypes } from '@app/shared/utility/functions/formatting/format-date.function';
import { Sort } from '@angular/material/sort';
import { sortCompare } from '@app/shared/utility/functions/sort-compare.function';
import { DatePipe } from '@angular/common';
import { formatGender } from '@app/shared/utility/functions/formatting/format-gender.function';
import { toTitleCase } from '@app/shared/utility/functions/formatting/to-title-case.function';
import moment from 'moment';


export enum SortKey {
    weight = 'weight',
    dateAddedToHistoryRecords = 'dateAddedToHistoryTable'
}

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    static readonly mpiRecordIdKey = 'mpiRecordId';
    static readonly mpiIdKey = 'mpiId';
    static readonly notAvailableValue = 'N/A';

    private readonly principalAddressType = 'p';

    constructor(
        public dialog: MatDialog
    ) { }

    /**
     * Validate is an string has numbers
     *
     * @param value string
     * @returns boolean
     */
    static hasNumber(value: string): boolean {
        return /\d/.test(value);
    }

    /**
     * Return a list of the pending status
     *
     * @returns string[]
     */
    static get pendingStatus(): string[] {
        return [MpiRecordStatus.addPendeing, MpiRecordStatus.mergePendeing, MpiRecordStatus.splitPendeing];
    }

    /**
     * Return true if the record has pending status
     *
     * @param record IMpiRecord
     * @returns boolean
     */
    static hasPendingStatus(record: IMpiRecord): boolean {
        const status = !!record ? (record.status || '').toLowerCase() : '';
        return !!UtilsService.pendingStatus.includes(status);
    }

    /**
     * Validate if the zip code is in a valid format
     *
     * @param value string - The zip code to validate
     * @returns boolean - Returns true if the zip code is in a valid format, otherwise false
     */
    static isZipCode(value: string): boolean {
        const zipCodePattern = /^(\d{5})(?:-\d{3,4})?$/;
        return !!value.match(zipCodePattern);
    }

    /**
     * Extract the first 5 digits of a zip code
     *
     * @param zipCode string - The zip code to extract from
     * @returns string - The first 5 digits of the zip code, or an empty string if the format is invalid
     */
    static getFirstFiveDigitsOfZipCode(zipCode: string): string {
        const zipCodePattern = /^(\d{5})(?:-\d{3,4})?$/;
        const match = zipCode.match(zipCodePattern);
        return match ? match[1] : '';
    }

    /**
     * Get age from date of birth
     *
     * @param dateOfBirth
     * @returns number
     */
    getAge(dateOfBirth: string, format: string = 'YYYYMMDD'): number {
        const dob = moment(dateOfBirth, format);
        const today = moment();
        return today.diff(dob, 'years');
    }

    /**
     * Set value in session storage
     *
     * @param storageKey
     * @param value
     */
    setDataInStorage(storageKey: MpiStorageKey, value: string) {
        sessionStorage.setItem(storageKey, value);
    }

    /**
     * Exist a last request in session storage
     */
    existRequestInStorage(): boolean {
        return !!(this.getDataFromStorage(MpiStorageKey.lastSearchRequest));
    }

    /**
     * Get value in session storage
     *
     * @param storageKey: MpiStorageKey
     */
    getDataFromStorage(storageKey: MpiStorageKey): string {
        return sessionStorage.getItem(storageKey);
    }

    /**
     * Remove ids duplicate in array
     * @param mpiRecordIds
     * @returns number[]
     */
    removeIdsDuplicates(mpiRecordIds: number[]): number[] {
        return [...new Set(mpiRecordIds)];
    }

    /**
     * Remove records duplicate in array
     * @param recordList
     * @param useKey
     * @returns IMpiRecord[]
     */
    removeDuplicatesInRecords(recordList: IMpiRecord[], useKey = UtilsService.mpiRecordIdKey): IMpiRecord[] {
        return [...new Map(recordList.map(record => [record[useKey], record])).values()];
    }

    /**
     * Remove value from session storage
     *
     * @param storageKey: MpiStorageKey
     */
    removeDataFromStorage(storageKey: MpiStorageKey) {
        sessionStorage.removeItem(storageKey);
    }

    /**
     * Set address root of record
     *
     * @param records
     * @returns IMpiRecord[]
     */
    setPrincipalAddress(records: IMpiRecord[]): IMpiRecord[] {
        return records.map(record => (!!record.address) ? record : {
            ...record, address: this.getMainAddress(record)
        });
    }

    /**
     * Get main addres from element IMpiRecord
     *
     * @param item
     * @returns IAddress
     */
    getMainAddress(item: IMpiRecord): IAddress {
        if (item.address) {
            return item.address;
        }
        return (item.addresses && item.addresses.length) ? item.addresses[0] : {};
    }

    /**
     * Get record id list from a list of records
     *
     * @param records
     * @return number[]
     */
    getRecordIds(records: IMpiRecord[]): number[] {
        return records.filter(record => record?.active).map(record => record.mpiRecordId);
    }

    /**
     * Build the data for the details of a record
     *
     * @param record
     */
    buildDetailData(record: IMpiRecord) {
        return [
            [
                { keyName: 'Street Address', valueName: this.getRecordValue('streetAddress', record) },
                { keyName: 'City', valueName: this.getRecordValue('city', record) },
                { keyName: 'State', valueName: this.getRecordValue('state', record) },
                { keyName: 'Zip Code', valueName: this.getRecordValue('zipCode', record) },
                { keyName: 'DOB', valueName: this.getRecordValue('dob', record) },
                { keyName: 'SSN', valueName: this.getRecordValue('ssn', record) }
            ],
            [
                { keyName: 'Phone Number', valueName: this.getRecordValue('phoneNumber', record) },
                { keyName: 'Gender', valueName: this.getRecordValue('gender', record) },
                { keyName: 'Birth Sex', valueName: this.getRecordValue('birthSex', record) },
                { keyName: 'Race', valueName: this.getRecordValue('race', record) },
                { keyName: 'DL State', valueName: this.getRecordValue('dlState', record) },
                { keyName: 'DL Number', valueName: this.getRecordValue('dlNumber', record) }
            ],
            [
                { keyName: 'Ethnicity', valueName: this.getRecordValue('ethnicity', record) },
                { keyName: 'Communication Language', valueName: this.getRecordValue('communicationLanguage', record) },
                { keyName: 'Username', valueName: this.getRecordValue('userName', record) },
                { keyName: 'Date', valueName: this.getRecordValue('date', record) },
                { keyName: 'Time', valueName: this.getRecordValue('time', record) }
            ]
        ];
    }

    /**
     * Sort elements by key
     *
     * @param elements
     * @param key
     * @param isAsc
     */
    sortByKey<T>(elements: T[], key: SortKey, isAsc = true): T[] {
        return elements.sort((a: T, b: T) =>
            sortCompare(a[key], b[key], isAsc));
    }

    /**
     * Sort data
     *
     * @param sort
     * @param elements
     * @returns IMpiRecord[]
     */
    sortData(sort: Sort, elements: IMpiRecord[]): IMpiRecord[] {
        if (!sort.active || sort.direction === '') {
            return elements;
        }

        return [...elements].sort((a: IMpiRecord, b: IMpiRecord) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'name': return sortCompare(a.firstName, b.firstName, isAsc);
                case 'firstName': return sortCompare(a.firstName, b.firstName, isAsc);
                case 'lastName': return sortCompare(a.lastName, b.lastName, isAsc);
                case 'userName': return sortCompare(a.userAdded, b.userAdded, isAsc);
                case 'source': return sortCompare(a.source, b.source, isAsc);
                case 'mpiId': return sortCompare(a.mpiId, b.mpiId, isAsc);
                case 'mpiHouseholdId': return sortCompare(a.householdId, b.householdId, isAsc);
                case 'status': return sortCompare(a.status, b.status, isAsc);
                case 'date': return sortCompare(a.dateAdded, b.dateAdded, isAsc);
                default: return 0;
            }
        });
    }

    private getRecordValue(recordName: string, record: IMpiRecord): string {
        return (this.recordMapping.has(recordName))
            ? this.recordMapping.get(recordName)(record) : '';
    }

    private readonly recordMapping: Map<string, any> = new Map<string, any>([
        ['city', (record: IMpiRecord) => this.getCityName(record)],
        ['state', (record: IMpiRecord) => this.getMainAddress(record).state || ''],
        ['zipCode', (record: IMpiRecord) => this.getMainAddress(record).zipCode || ''],
        ['race', (record: IMpiRecord) => record?.usCoreRace?.text || ''],
        ['userName', (record: IMpiRecord) => this.getUserName(record.userAdded || '')],
        ['phoneNumber', (record: IMpiRecord) => (record.phones && record.phones.length) ? (record.phones[0]?.phoneNumber || '') : ''],
        ['dlState', (record: IMpiRecord) => record.dlState || ''],
        ['dlNumber', (record: IMpiRecord) => record.dlNumber || ''],
        ['ethnicity', (record: IMpiRecord) => record?.usCoreEthnicity?.text || ''],
        ['date', (record: IMpiRecord) => this.getDate(record?.dateAdded || '')],
        ['time', (record: IMpiRecord) => this.getTime(record?.dateAdded || '')],
        ['streetAddress', (record: IMpiRecord) => this.getStreetAddress(record)],
        ['dob', (record: IMpiRecord) => this.getDOB(record.dateOfBirth || '')],
        ['ssn', (record) => this.getSSN(record.ssn || '')],
        ['gender', (record) => this.getGender(record?.gender || '')],
        ['birthSex', (record) => this.getGender(record?.birthSex || '')],
        ['communicationLanguage', (record: IMpiRecord) => this.getLanguage(record)],
    ]);

    private getCityName(record: IMpiRecord): string {
        const cityName = this.getMainAddress(record).cityName || '';
        return (cityName === UtilsService.notAvailableValue) ? cityName : toTitleCase(cityName);
    }

    private getUserName(userName: string): string {
        return (userName === UtilsService.notAvailableValue) ? userName : toTitleCase(userName);
    }

    private getDate(date: string): string {
        return (date === UtilsService.notAvailableValue)
            ? UtilsService.notAvailableValue : new DatePipe('en_US').transform(date, 'MM/dd/yyyy')
    }

    private getTime(date: string): string {
        return (date === UtilsService.notAvailableValue)
            ? UtilsService.notAvailableValue : new DatePipe('en_US').transform(date, 'shortTime')
    }

    private getStreetAddress(record: IMpiRecord): string {
        const address = this.getMainAddress(record);
        return `${address.addressLine1 || ''} ${address.addressLine2 || ''}`;
    }

    private getDOB(dob: string): string {
        return (dob === UtilsService.notAvailableValue)
            ? UtilsService.notAvailableValue
            : formatDate(dob, { dateFormat: 'MM/dd/yyyy', initialStringFormat: InitialDateStringFormatTypes.YYYYMMDD })
    }

    private getSSN(ssn: string): string {
        return (ssn === UtilsService.notAvailableValue) ? UtilsService.notAvailableValue : formatSSN(ssn)
    }

    private getGender(gender: GenderType): string {
        return (gender === UtilsService.notAvailableValue) ? UtilsService.notAvailableValue : formatGender(gender);
    }

    private getLanguage(record: IMpiRecord): string {
        return (record?.communicationDtoList && record?.communicationDtoList.length)
            ? (record.communicationDtoList[0]?.languageDto?.display || '') : '';
    }
}
