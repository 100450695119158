import { IMpiRecord } from '@services/backend/mci/search.config';
import { Pipe, PipeTransform } from '@angular/core';
import { formatName } from '@app/shared/utility/functions/formatting/format-name.function';
import { NameInterfaceLowercase } from '@app/core/data-models/common/name-lowercase.interface';

@Pipe({
    name: 'formatDetailsName',
    standalone: true
})
export class FormatDetailsNamePipe implements PipeTransform {

    transform(record: Partial<IMpiRecord>, reverseOrder = false): string {
        if (!record) {
            return '';
        }

        const valueMapping: NameInterfaceLowercase = {
            first: record.firstName || '',
            middle: record.middleName || '',
            last: record.lastName || '',
            suffix: record.nameSuffix || ''
        };
        return formatName(valueMapping, reverseOrder);
    }
}
