import { AppPermissions } from './acl-permissions-constants';
import { ACL_PERMISSIONS_VALUE as ACL } from '@app/core/services/acl/config/acl-permissions';
import { ProductRouteModules } from '@models/enums/product-route-modules';

interface Rules {
    rule: string;
    permissions: string[];
    regex?: boolean;
    module: ProductRouteModules;
}

/* eslint-disable max-len */
export const routesPermissionsValue: Rules[] = [
    {
        rule: ACL.USER_PREFERENCES,
        permissions: [AppPermissions.identity.viewModule],
        module: ProductRouteModules.None,
        regex: true,
    },
    {
        rule: ACL.MCI_SEARCH,
        permissions: [AppPermissions.mci.viewModule],
        module: ProductRouteModules.MCI,
        regex: true
    },
    {
        rule: ACL.MCI_WORKLIST,
        permissions: [AppPermissions.mci.viewModule],
        module: ProductRouteModules.MCI,
    },
    {
        rule: ACL.MCI_PENDING_TRANSACTIONS,
        permissions: [AppPermissions.mci.viewModule],
        module: ProductRouteModules.MCI,
    },
    {
        rule: ACL.MCI_REPORTS,
        permissions: [AppPermissions.mci.viewModule],
        module: ProductRouteModules.MCI,
    },
    {
        rule: ACL.MCI_MERGE,
        permissions: [AppPermissions.mci.merge],
        module: ProductRouteModules.MCI
    },
    {
        rule: ACL.MCI_SPLIT,
        permissions: [AppPermissions.mci.split],
        module: ProductRouteModules.MCI
    },
    {
        rule: ACL.MCI_HELP,
        permissions: [AppPermissions.mci.viewModule],
        module: ProductRouteModules.MCI
    },
];
