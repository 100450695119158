import { BaseModel } from 'app/core/data-models/base-model';

export enum NvpType {
    user = 'user',
    company = 'company'
}

export interface NvpInterface {
    id?: number;
    name?: string;
    value?: boolean | any;
    group?: string;
    description?: string;
    label?: string; // Local
    type?: string;
}

export class Nvp extends BaseModel implements NvpInterface {
    constructor(public id?: number,
                public name?: string,
                public value?: boolean | any,
                public group?: string,
                public description?: string,
                public label?: string, // Local
                public type?: string
    ) {
        super();
    }

    /**
     * Converts the model into a key value pair for xhr
     * @returns {{}}
     */
    public getKeyValuePair() {
        const obj = {};
        obj[this.name] = this.value;
        return obj;
    }
}
