import { AppLog } from '@app/core/services/log/app-log.service';
import { IMpiRecord } from './search.config';
import { Injectable } from '@angular/core';
import { isArray, isObject } from 'lodash';
import { LogPriority } from '../../log/log-priority.enum';
import { IBestDetails } from '@app/containers/mci/search/details/search-details.config';
import { UtilsService } from './utils.service';
import * as fromConfig from '@app/containers/mci/shared/compare-records/compare-records-modal.config';

@Injectable()
export class CompareRecordsService {

    constructor(private logger: AppLog) {}

    /**
     * Returns the number of differences between a record and a public record
     *
     * @param record IMpiRecord - The record to compare
     * @param publicRecord IBestDetails - The public record to compare against
     * @returns number - The number of differences between the two records
     */
    getDifferencesWithPublicRecord(record: IMpiRecord, publicRecord: IBestDetails): number {
        if (!record || !publicRecord) {
            return 0;
        }
        const firstRecord: fromConfig.RecordMappingInterface[] = this.getFormattedData(record, true);
        const secondRecord: fromConfig.RecordMappingInterface[] = this.getFormattedData(publicRecord, true);
        const nameMatch = +this.isSameName(record, publicRecord);
        const result = [...firstRecord].filter((item, index) => {
            const fisrtValue = firstRecord[index].value;
            const secondValue = secondRecord[index].value;
            return (this.isValidValues(fisrtValue, secondValue) && fisrtValue !== secondValue);
        });
        return result.length + nameMatch;
    }

    /**
     * Validate if the name is the same in two records
     *
     * @param firstRecord fromConfig.NameCompareRecord - The first record to compare
     * @param secondRecord fromConfig.NameCompareRecord - The second record to compare
     * @returns boolean - Returns true if the names are the same, otherwise false
     */
    isSameName(firstRecord: fromConfig.NameCompareRecord, secondRecord: fromConfig.NameCompareRecord): boolean {
        return Object.values(fromConfig.NameKeys).some(key => {
            return firstRecord[key] !== secondRecord[key];
        });
    }

    /**
     * Returns records with differences highlighted
     *
     * @param firstRecord IMpiRecord
     * @param secondRecord IMpiRecord | IBestDetails
     * @param isPublicRecord boolean
     * @param sortResult boolean
     * @returns ResultCompareRecordsInterface
     */
    setHighlighted(firstRecord: IMpiRecord, secondRecord: IMpiRecord | IBestDetails, isPublicRecord = false, sortResult = true): fromConfig.ResultCompareRecordsInterface {
        const firstRecordFormated: fromConfig.RecordMappingInterface[] = this.getFormattedData(firstRecord, isPublicRecord);
        const secondRecordFormated: fromConfig.RecordMappingInterface[] = this.getFormattedData(secondRecord, isPublicRecord);

        firstRecordFormated.forEach((record, index) => {
            const { value: firstValue } = firstRecordFormated[index];
            const { value: secondValue } = secondRecordFormated[index];

            if (!this.isValidValues(firstValue, secondValue)) {
                firstRecordFormated[index] = { ...firstRecordFormated[index], showRow: false };
                secondRecordFormated[index] = { ...secondRecordFormated[index], showRow: false };
            } else {
                const highlighted = this.areValuesDifferent(firstValue, secondValue);
                firstRecordFormated[index] = { ...firstRecordFormated[index], highlighted };
                secondRecordFormated[index] = { ...secondRecordFormated[index], highlighted };
            }
        });

        return {
            [fromConfig.CompareIndex.first]: sortResult ? this.sortData(firstRecordFormated) : firstRecordFormated,
            [fromConfig.CompareIndex.second]: sortResult ? this.sortData(secondRecordFormated) : secondRecordFormated
        };
    }

    /**
     * Format a value depending on a type
     * Ex: SSN, DOB
     *
     * @param transform string
     * @param value string
     * @returns string
     */
    formatRecord(formatType: string, value: string): string {
        try {
            return fromConfig.FormatRecord[formatType](value);
        } catch (error) {
            this.logger.error(`Error formating ${formatType.toUpperCase()}`, { logPriority: LogPriority.MEDIUM }, error);
            return value;
        }
    }

    /**
     * Compare two values and determine if they are different
     *
     * @param firstValue any - The first value to compare
     * @param secondValue any - The second value to compare
     * @returns boolean - Returns true if the values are different, otherwise false
     */
    private areValuesDifferent(firstValue: any, secondValue: any): boolean {
        if ((firstValue && UtilsService.isZipCode(firstValue)) || (secondValue && UtilsService.isZipCode(secondValue))) {
            return !this.isSameZipCode(firstValue, secondValue);
        }
        return firstValue !== secondValue;
    }

    /**
     * Validate if the zip code is the same in two records
     *
     * @param firstZipCode string
     * @param secondZipCode string
     * @returns boolean
     */
    private isSameZipCode(firstZipCode: string, secondZipCode: string): boolean {
        return UtilsService.getFirstFiveDigitsOfZipCode(firstZipCode) === UtilsService.getFirstFiveDigitsOfZipCode(secondZipCode);
    }

    /**
     * Returns formatted data
     *
     * @param record *
     * @param isPublicRecord boolean
     * @returns RecordMappingInterface[]
     */
    private getFormattedData(record: any, isPublicRecord = false): fromConfig.RecordMappingInterface[] {
        const mapping = isPublicRecord === true ? fromConfig.publicRecordMapping : fromConfig.recordMapping;
        return this.formatData({...record, bestDetails: null}, mapping);
    }

    /**
     * Returns a standard format for comparing the record
     *
     * @param record any
     * @param mapping RecordMappingInterface[]
     * @returns RecordMappingInterface[]
     */
    private formatData(record: any, mapping: fromConfig.RecordMappingInterface[]): fromConfig.RecordMappingInterface[] {
        return mapping.map(mapValue => {
            const [mainKey, secundaryKey] = this.getKeys(mapValue.key);
            let value: string = '';

            const recordValue = record[mainKey];
            if (record.hasOwnProperty(mainKey) && !isObject(recordValue)) {
                value = recordValue ? recordValue : null;
            } else if (isArray(recordValue)) {
                value = this.getArrayValue(recordValue, secundaryKey);
            } else {
                value = this.getObjectValue(record, mainKey);
            }
            const result = value ? (mapValue.transform ? this.formatRecord(mapValue.transform, value) : value) : null;
            return this.setCompareData(mapValue.label, result);
        });
    }

    /**
     * Retrieves the first value from an array. If the first value is an object, it retrieves the value of the specified secondary key
     *
     * @param recordValue - The array of values
     * @param secundaryKey - The secondary key to retrieve the value from the first object in the array
     * @returns The first value or the value of the secondary key from the first object, or null if not found
     */
    private getArrayValue(recordValue: any[], secundaryKey?: string): any {
        const firstValue = recordValue[0];
        return (typeof firstValue === 'string')
            ? firstValue
            : (secundaryKey && firstValue?.[secundaryKey]) ?? null;
    }

    /**
     * Retrieves the value of the specified main key from an object
     *
     * @param record - The object to search
     * @param mainKey - The key to retrieve the value for
     * @returns The value of the main key, or null if not found
     */
    private getObjectValue(record: any, mainKey: string): any {
        const data = Object.values(record).find(currentValue => isObject(currentValue) && currentValue.hasOwnProperty(mainKey));
        return data?.[mainKey] ?? null;
    }

    /**
    * Splits a key into an array of keys if it contains a dot, otherwise returns an array with the key and undefined
    *
    * @param key - The key to split
    * @returns An array of keys
    */
    private getKeys(key: string): string[] {
        return key.includes('.') ? key.split('.') : [key, undefined];
    }

    /**
     * Checks if at least one of the values is not null
     *
     * @param firstValue - The first value to check
     * @param secondValue - The second value to check
     * @returns True if at least one value is not null, otherwise false
     */
    private isValidValues(firstValue: any, secondValue: any): boolean {
        return !!(firstValue !== null || secondValue !== null);
    }

    /**
     * Sorts an array of record mappings by the highlighted property in descending order
     *
     * @param data - The array of record mappings to sort
     * @returns The sorted array of record mappings
     */
    private sortData(data: fromConfig.RecordMappingInterface[]): fromConfig.RecordMappingInterface[] {
        return data.sort((a: any, b: any) => {
            return b.highlighted - a.highlighted;
        });
    }

    /**
     * Creates a record mapping object with the specified label, value, and highlighted status
     *
     * @param label - The label for the record mapping
     * @param value - The value for the record mapping
     * @param highlighted - The highlighted status for the record mapping (default is false)
     * @returns The record mapping object
     */
    private setCompareData(label: string, value: any, highlighted: boolean = false): fromConfig.RecordMappingInterface {
        return { label, value, highlighted };
    }
}
