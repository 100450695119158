export interface IAuthConfig {
    loginRedirect: string;
    logoutRedirect: string;
    forbiddenRedirect: string;
    landingMci: string;
    landingDefault: string;
}

export const AUTH_CONFIG: IAuthConfig = {
    loginRedirect: 'product-use',
    logoutRedirect: 'auth/login',
    forbiddenRedirect: '/product-use',
    landingMci: '/mci/search',
    landingDefault: '/product-use',
};

