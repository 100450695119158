import { DatePipe } from '@angular/common';

export enum InitialDateStringFormatTypes {
    MMDDYYYY,
    YYYYMMDD,
    YYYYMM,
    MMYYYY
}

export interface DateOptions {
    dateFormat?: string;
    initialStringFormat?: InitialDateStringFormatTypes;
}

const DEFAULT_OPTIONS = {
    dateFormat: 'MM/dd/yyyy',
    initialStringFormat: null,
};

/**
 * Takes a date string and date format then returns in expected date format
 * @param {string} date
 * @param {object} options
 * @return {string}
 */
export function formatDate(date: Date | string, options: DateOptions = {}): string {
    options = { ...DEFAULT_OPTIONS, ...options };
    let formattedDate = '';
    const { initialStringFormat, dateFormat } = options;


    if (typeof (date) === 'string') {
        switch (initialStringFormat) {
            case InitialDateStringFormatTypes.MMDDYYYY:
                date = date.slice(0, 2) + '/' + date.slice(2, 4) + '/' + date.slice(4);
                break;
            case InitialDateStringFormatTypes.YYYYMMDD:
                date = date.slice(4, 6) + '/' + date.slice(6) + '/' + date.slice(0, 4);
                break;
            case InitialDateStringFormatTypes.YYYYMM:
                date = date.slice(4) + '/01/' + date.slice(0, 4);
                break;
            case InitialDateStringFormatTypes.MMYYYY:
                date = date.slice(2, 6) + '/' + date.slice(0, 2);
                break;
            default:
                // for backwards compatibility
                if (date.length === 6) {
                    date = date.slice(0, 4) + '/' + date.slice(4, 6);
                }
                if (date.length === 8) {
                    date = date.slice(0, 4) + '/' + date.slice(4, 6) + '/' + date.slice(6, 8);
                }
                break;
        }
        formattedDate = new DatePipe('en_US').transform(new Date(date), dateFormat);
    } else {
        formattedDate = new DatePipe('en_US').transform(date, dateFormat);
    }

    return formattedDate;
}
