import { GenderType } from "@app/core/services/backend/mci/search.config";

const genderMapping = {
    M: 'Male',
    F: 'Female'
}

export const formatGender = (gender: GenderType): string => {
    gender = (gender || '').toUpperCase() as GenderType;
    if (gender !== 'M' && gender !== 'F') {
        return '';
    }
    return genderMapping[gender];
}
