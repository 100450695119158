import { showErrorMessage } from '@app/store/actions/ui.actions';
import { IMpiRecord, MpiRecordStatus } from '@app/core/services/backend/mci/search.config';
import { UtilsService, SortKey } from './utils.service';
import { Injectable } from '@angular/core';
import { BackendService } from '@services';
import { mpiConfig } from '@config/app-config.constants';
import { AppLog } from '@services/log/app-log.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';
import { MpiWorklistSection, ISearchResponse } from './search.config';
import { selectTotalIdentities, selectTotalTransactions } from '@app/store/selectors/worklist.selectors';
import { Store, select } from '@ngrx/store';
import { State } from '@app/store/states';

@Injectable({
    providedIn: 'root'
})
export class WorklistService extends BackendService {

    private readonly recordsLimit = 50;

    constructor(
        protected http: HttpClient,
        protected logger: AppLog,
        protected store: Store<State>,
        private utilsService: UtilsService
    ) {
        super(http, logger, store);
    }

    /**
     * Get items from scratchpad
     *
     * @returns Observable<IMpiRecord[]>
     */
    getItemsFromScratchpad(section: MpiWorklistSection): Observable<IMpiRecord[]> {
        const endpoint = (section === MpiWorklistSection.identities)
            ? mpiConfig.api.mciGetIdentityList
            : mpiConfig.api.mciGetTransactionList;
        return this.doGet(endpoint).pipe(map(response => this.handleSuccessGetRespsonse(response)));
    }

    /**
     * Add items to identity list
     *
     * @param records
     * @returns Observable<boolean>
     */
    addItemsToWorklist(records: IMpiRecord[], section: MpiWorklistSection): Observable<boolean> {
        return this.validateLimit(records.length, section).pipe(
            switchMap(validLimit => {
                if (!validLimit) {
                    this.store.dispatch(showErrorMessage({
                        message: `${this.recordsLimit} record limit reached.`,
                        error: { error: 'Validation failed' }
                    }));
                    return of(false);
                }

                const endpoint = (section === MpiWorklistSection.identities)
                    ? mpiConfig.api.mciAddToIdentityList
                    : mpiConfig.api.mciAddToTransactionList;

                return this.doPost(endpoint, JSON.stringify(records))
                    .pipe(map(response => response.data?.success || false));
            })
        );
    }

    /**
     * Delete an item from identity list
     *
     * @param mpiRecordId
     * @param section
     * @returns Observable<boolean>
     */
    delete(mpiRecordId: number, section: MpiWorklistSection): Observable<boolean> {
        const endpoint = (section === MpiWorklistSection.identities)
            ? mpiConfig.api.mciDeleteFromIdentityList(mpiRecordId)
            : mpiConfig.api.mciDeleteFromTransactionList(mpiRecordId);

        return this.doDelete(endpoint).pipe(map(response => response.data?.success));
    }

    /**
     * Update records status
     *
     * @param records
     * @returns IMpiRecord[]
     */
    updateStatus(records: IMpiRecord[]): IMpiRecord[] {
        return records.map(record => (UtilsService.hasPendingStatus(record))
            ? record : { ...record, status: MpiRecordStatus.underEvaluation }
        );
    }

    /**
     * Validate that the limit of allowed records is not exceeded
     *
     * @param countNewRecords
     * @param section
     * @returns boolean
     */
    private validateLimit(countNewRecords: number, section: MpiWorklistSection): Observable<boolean> {
        const totalRecords$ = (section === MpiWorklistSection.identities)
            ? this.store.pipe(select(selectTotalIdentities), take(1))
            : this.store.pipe(select(selectTotalTransactions), take(1));
        return totalRecords$.pipe(
            map(existingRecords => (existingRecords + countNewRecords) <= this.recordsLimit)
        );
    }

    /**
     * Handle the success response
     *
     * @param response
     * @returns IMpiRecord[]
     */
     private handleSuccessGetRespsonse(response: ISearchResponse): IMpiRecord[] {
        const data = this.utilsService.setPrincipalAddress(response.data);
        return this.utilsService.sortByKey<IMpiRecord>([...data], SortKey.weight, false);
    }
}
