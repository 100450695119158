import { IBestDetails, IHistoryTransaction, IListProfileBoosterAttributesByMpiId, IMortalityInsights } from '@containers/mci/search/details/search-details.config';
import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import * as fromRecordsConfig from '@services/backend/mci/search.config';


export const RecordsActions = createActionGroup({
    source: 'RECORDS',
    events: {
        'Search': props<{ params?: fromRecordsConfig.ISearchParam }>(),
        'Do Last Search': emptyProps(),
        'Set Search Records': props<{ records: fromRecordsConfig.IMpiRecord[] }>(),
        'Clear Search Result': emptyProps(),
        'Set Split Data': props<{ transactions: fromRecordsConfig.IMpiRecord[], identity?: fromRecordsConfig.IMpiRecord }>(),
        'Clear Split Data': emptyProps(),
        'Set Merge Data': props<{ identities: fromRecordsConfig.IMpiRecord[] }>(),
        'Clear Merge Data': emptyProps(),
        'Update Search Records Status': emptyProps(),
        'Selected Record In Search Result': props<{ id: number }>(),
        'Clear Records Selected In Search Result': emptyProps(),
        'Set Transactions': props<{ records: fromRecordsConfig.IMpiRecord[] }>(),
        'Load Identity Details': props<{ mpiId: string, loadHouseholdInfo: boolean }>(),
        'Clear Identity Details': emptyProps(),
        // In case isPublicRecord is true, the public record data must be passed as the second record
        'Compare Records': props<{
            firstRecord: fromRecordsConfig.IMpiRecord,
            secondRecord: fromRecordsConfig.IMpiRecord
        }>(),
        'Compare Public Record': props<{
            record: fromRecordsConfig.IMpiRecord
            publicRecord: IBestDetails
        }>()
    },
});

export const HistoryRecordsActions = createActionGroup({
    source: 'HISTORY RECORDS',
    events: {
        'Load Data': props<{ mpiId: string }>(),
        'Set Data': props<{ records: IHistoryTransaction[] }>(),
        'Clear Data': emptyProps()
    },
});

export const ProfileBoosterActions = createActionGroup({
    source: 'PROFILE BOOSTER',
    events: {
        'Load In Batch': props<{ mpiIds: string[] }>(),
        'Load By MpiId': props<{ mpiId: string }>(),
        'Set Data': props<{ data: IListProfileBoosterAttributesByMpiId }>(),
        'Clear Data': emptyProps()
    },
});

export const HouseholdInsightsActions = createActionGroup({
    source: 'HOUSEHOLD INSIGHTS',
    events: {
        'Load Data': props<{ householdId: string }>(),
        'Set Data': props<{ records: fromRecordsConfig.IMpiRecord[] }>()
    },
});

export const setBestDetails = createAction('[BEST DETAILS] Set Data',
    props<{ data: IBestDetails }>());

export const setMortalityInsights = createAction('[Mortality Insights] Set Data',
    props<{ data: IMortalityInsights }>());
