import { IMpiRecord } from '@services/backend/mci/search.config';
import { Action, createReducer, on } from '@ngrx/store';
import { PendingTransactionsActions } from '../actions/pending-transactions.actions';


export const pendingTransactionsKey = 'pendingTransactions';

export interface PendingTransactionsState {
    pendingTransactions: IMpiRecord[];
}

export const initialPendingTransactionsState: PendingTransactionsState = {
    pendingTransactions: null,
};

export const pendingTransactionsReducers = createReducer(
    initialPendingTransactionsState,
    on(PendingTransactionsActions.setPendingTransactions, (state, { records }) => ({ ...state, pendingTransactions: records })),
);

export function pendingTransactionsReducer(state: PendingTransactionsState | undefined, action: Action): PendingTransactionsState {
    return pendingTransactionsReducers(state, action);
}
