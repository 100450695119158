import { createAction, props } from '@ngrx/store';

export const uiLoading = createAction('[UI] Loading');

export const uiLoaded = createAction('[UI] Loaded');

export const cancelRequests = createAction('[UI] Cancel Requests');

export const showErrorMessage = createAction('[UI] Show Error Message',
    props<{ message: string, title?: string, width?: number; error?: any }>());

export const showConfirmMessage = createAction('[UI] Show Confirmation Message',
    props<{ message: string, title?: string, width?: number }>());

export const confirmationModalSuccess = createAction('[UI] Confirmation Modal Success',
    props<{ confirmed }>());

export const closeModal = createAction('[UI] Close Modal');
