import { Injectable } from '@angular/core';

@Injectable()
export class RouteActivationService {

    private listeners: ((path: string) => boolean)[] = [];

    public beforeActive(path: string): boolean {
        let canActive = true;
        this.listeners.forEach((listener) => {
            canActive = (canActive && listener(path));
        });
        return canActive;
    }

    public subscribe(listener: (path: string) => boolean): () => void {
        this.listeners.push(listener);
        return () => {
            this.listeners = this.listeners.filter(l => l !== listener);
        };
    }

}
