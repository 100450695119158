import { LogPriority } from '@app/core/services/log/log-priority.enum';
import { defaultEnvironmentValues, IEnvironment } from './environment.default';

export const environment: IEnvironment = {
    ...defaultEnvironmentValues,
    production: true,
    env: 'prod',
    debug: false,
    baseModuleUrl: 'https://amplifyid.lexisnexisrisk.com',
    logPriorityCutoff: LogPriority.MEDIUM,
    appInsights: {
        enable: true,
        connectionString: 'InstrumentationKey=3c3543f7-b9e2-f9b7-b523-bbec7c329846;EndpointSuffix=applicationinsights.us;IngestionEndpoint=https://usgovvirginia-1.in.applicationinsights.azure.us/',
        trackRouthChanges: true,
        autoTrackPageVisitTime: true
    }
};
