import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RoutingState {
    private static SESSION_STORAGE_HISTORY_KEY = 'last-route-visited';

    private set history(val) {
        // Keep history through page refresh
        sessionStorage.setItem(RoutingState.SESSION_STORAGE_HISTORY_KEY, val);
    }

    private get history() {
        return sessionStorage.getItem(RoutingState.SESSION_STORAGE_HISTORY_KEY);
    }

    constructor(
        private router: Router
    ) {
    }

    setUrlHistoryInLocalStore(): void {
        this.history = this.history ? this.history : '/home';
        this.router.events
            .pipe(
                filter((event: any) => event instanceof RoutesRecognized),
                pairwise()
            ).subscribe((event: any) => {
            this.history = event[0].urlAfterRedirects;
        });
    }

    getPreviousUrl(): string {
        return this.history;
    }
}
