export enum AppInsightsSeverityLevelEnum {
    debug = 0,
    info = 1,
    notice = 1,
    alert = 1,
    warning = 2,
    error = 3,
    critical = 4,
    emergency = 4,
}
