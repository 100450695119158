import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { AppLog } from '@app/core/services/log/app-log.service';
import { LogPriority } from '@app/core/services/log/log-priority.enum';
import { ReportsService } from '@app/core/services/backend/mci/reports.service';
import { ReportType } from '@app/containers/mci/reports/reports.config';
import { Observable, of } from 'rxjs';
import { ApiResponseReportsTypes } from '@app/core/services/backend/interfaces/reports-response.interface';
import { ResponseMessage } from '@app/core/config/response-message.config';
import * as fromActions from '@app/store/actions';

@Injectable()
export class ReportsEffect {

    constructor(
        private actions$: Actions,
        private logger: AppLog,
        private reportsService: ReportsService,
    ) {
    }

    loadReports$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.ReportsActions.loadReports),
        exhaustMap(({ reportType, startDate, endDate }) => {
            return this.getReportByType(reportType, startDate, endDate).pipe(
                map(reports => fromActions.ReportsActions.setReports({ reports })),
                catchError(error => {
                    this.logger.error('Error loading Reports', { logPriority: LogPriority.HIGH }, error);
                    return of(
                        fromActions.ReportsActions.setReports({ reports: null }),
                        fromActions.showErrorMessage({ message: ResponseMessage.ERROR_MESSAGES.GENERATE_REPORTS_ERROR })
                    );
                })
            );
        })
    ));

    private getReportByType(reportType: ReportType, startDate: string, endDate: string): Observable<ApiResponseReportsTypes> {
        switch (reportType) {
            case ReportType.activities:
                return this.reportsService.getActivitiesSummaryReports(startDate, endDate);
            case ReportType.dataComparison:
                return this.reportsService.getDataComparisonReports(startDate, endDate);
            case ReportType.household:
                return this.reportsService.getHouseholdReports(startDate, endDate);
            case ReportType.remediation:
                    return this.reportsService.getRemediationReports(startDate, endDate);
            default:
                return of(null);
        }
    }
}
