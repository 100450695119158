import { SearchService } from '@core/services/backend/mci/search.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, takeUntil, exhaustMap } from 'rxjs/operators';
import { AppLog } from '@app/core/services/log/app-log.service';
import { LogPriority } from '@app/core/services/log/log-priority.enum';
import { of } from 'rxjs';
import { PendingTransactionsActions } from '../actions/pending-transactions.actions';
import { cancelRequests } from '../actions/ui.actions';

@Injectable()
export class PendingTransactionsEffect {

    constructor(
        private actions$: Actions,
        private logger: AppLog,
        private searchService: SearchService,
    ) {
    }

    loadPendingTransactions$ = createEffect(() => this.actions$.pipe(
        ofType(PendingTransactionsActions.loadPendingTransactions),
        exhaustMap(() => {
            return this.searchService.getPendingTransactions().pipe(
                takeUntil(this.actions$.pipe(ofType(cancelRequests))),
                map(records => PendingTransactionsActions.setPendingTransactions({ records })),
                catchError(error => {
                    this.logger.error('Error loading Pending Transactions', { logPriority: LogPriority.HIGH }, error);
                    return of(PendingTransactionsActions.setPendingTransactions({ records: [] }));
                })
            );
        })
    ));
}
