import { Action, createReducer, on } from '@ngrx/store';
import * as fromAction from '../actions/ui.actions';


export const uiKey = 'ui';

export interface UIState {
    loading: number;
    isModalOpen: boolean;
    confirmation: boolean;
}

export const initialUIState: UIState = {
    loading: 0,
    isModalOpen: false,
    confirmation: null
};

export const uiReducers = createReducer(
    initialUIState,
    on(fromAction.uiLoading, (state) => ({ ...state, loading: state.loading + 1 })),
    on(fromAction.uiLoaded, (state) => ({ ...state, loading: state.loading - 1 })),
    on(fromAction.showErrorMessage, (state) => ({ ...state, isModalOpen: true })),
    on(fromAction.closeModal, (state) => ({ ...state, isModalOpen: false })),
    on(fromAction.showConfirmMessage, (state) => ({ ...state, isModalOpen: true, confirmation: null })),
    on(fromAction.confirmationModalSuccess, (state, { confirmed }) => ({
        ...state, isModalOpen: false, confirmation: confirmed
    })),
);

export function uiReducer(state: UIState | undefined, action: Action): UIState {
    return uiReducers(state, action);
}
