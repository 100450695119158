import { IMpiRecord, MpiWorklistSection } from '@services/backend/mci/search.config';
import { createActionGroup, emptyProps, props } from '@ngrx/store';


export const WorklistActions = createActionGroup({
    source: 'WORKLIST',
    events: {
        'Remove Record': props<{ record: IMpiRecord, section: MpiWorklistSection }>(),
        'Clear Reports': emptyProps(),
        'Load Identities': emptyProps(),
        'Load Identities Fail': emptyProps(),
        'Load Identities Success': props<{ records: IMpiRecord[] }>(),
        'Add Identities': props<{ identities: IMpiRecord[] }>(),
        'Set Identities': props<{ identities: IMpiRecord[] }>(),
        'Remove Identities': props<{ mpiRecordIds: number[] }>(),
        'Remove One Identity': props<{ mpiRecordId: number }>(),
        'Update Identity Ids Selected': props<{ id: number }>(),
        'Clear Identity Ids Selected': emptyProps(),
        'Load Transactions': emptyProps(),
        'Load Transactions Fail': emptyProps(),
        'Load Transactions Success': props<{ records: IMpiRecord[] }>(),
        'Add Transactions': props<{ transactions: IMpiRecord[] }>(),
        'Set Transactions': props<{ transactions: IMpiRecord[] }>(),
        'Remove Transactions': props<{ mpiRecordIds: number[] }>(),
        'Remove One Transaction': props<{ mpiRecordId: number }>(),
        'Update Transaction Ids Selected': props<{ id: number }>(),
        'Clear Transaction Ids Selected': emptyProps(),
    },
});
