import { uiLoading, uiLoaded } from '@app/store/actions/ui.actions';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '@app/store/states';
import { mpiConfig } from '../config/app-config.constants';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    constructor(
        private store: Store<State>
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.skyRequest(request.url)) {
            this.store.dispatch(uiLoading());
            return next.handle(request).pipe(
                finalize(() => this.store.dispatch(uiLoaded()))
            );
        } else {
            return next.handle(request);
        }
    }

    private skyRequest(url: string) {
        return this.pathExcepciones.some((path: string) => url.includes(path))
    }

    get pathExcepciones(): string[] {
        return [
            mpiConfig.api.addLog
        ];
    }
}

