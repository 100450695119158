import { environment } from 'environments/environment';
import { templateLiteralTag } from '@shared/utility/functions/template-literal-tag';

export interface IApi {
    baseUrl: string;

    // Logs
    addLog: string;

    // Authentication
    compliances: string;
    logout: string;
    renew: string;
    identity: string;

    // SSO
    sso: (token: string) => string;
    ssoSwitchSession: (moduleName: string) => string;

    // Search
    mciSearch: string;
    mciElementByMciId: (mpiId: string) => string;
    mciProfileBoosterInBatch: string;
    mciPendingTransactions: string;
    mciHistoryRecordsByMciRecordId: (mpiId: string) => string;

    // Worklist
    mciGetIdentityList: string;
    mciAddToIdentityList: string;
    mciDeleteFromIdentityList: (id: number) => string;
    mciGetTransactionList: string;
    mciAddToTransactionList: string;
    mciDeleteFromTransactionList: (id: number) => string;

    // Merge
    mciMerge: string;
    mciSplit: string;

    // Reports
    activitiesSummaryReports: string;
    dataComparisonReport: string;
    remediationReport: string;
    householdIdReport: string;
}

export const API_CONFIG: IApi = {
    baseUrl: environment.baseUrl,

    // Logs
    addLog: 'v1/logs/add',

    // Authentication
    compliances: 'v1/compliances',
    logout: 'v1/logout',
    renew: 'v1/renew',
    identity: 'v1/account',

    // SSO
    sso: templateLiteralTag`v1/sso/${0}`,
    ssoSwitchSession: templateLiteralTag`v1/sso/switch/${0}`,

    // Search
    mciSearch: 'v1/search',
    mciElementByMciId: templateLiteralTag`v1/identity/${0}`,
    mciProfileBoosterInBatch: 'v1/profile-booster-batch',
    mciPendingTransactions: 'v1/pending-transactions',
    mciHistoryRecordsByMciRecordId: templateLiteralTag`v1/history-records/${0}`,

    // Worklist
    mciGetIdentityList: 'v1/identity-list',
    mciAddToIdentityList: 'v1/identity-list',
    mciDeleteFromIdentityList: templateLiteralTag`v1/identity-list/${0}`,
    mciGetTransactionList: 'v1/transaction-list',
    mciAddToTransactionList: 'v1/transaction-list',
    mciDeleteFromTransactionList: templateLiteralTag`v1/transaction-list/${0}`,

    // Merge
    mciMerge: 'v1/merge',
    mciSplit: 'v1/split',

    // Reports
    activitiesSummaryReports: 'v1/reports/activities-summary',
    dataComparisonReport: 'v1/reports/data-comparison',
    remediationReport: 'v1/reports/remediation',
    householdIdReport: 'v1/reports/household-id'
};
