export interface IExternalLinks {
    termsCondUrl: string;
    privacyPolicyUrl: string;
    copyrightUrl: string;
    supportPhone: string;
    supportEmail: string;
    ppaSupportEmail: string;
    supportChat: string;
    siteHelp: string;
    ppaHelp: string;
    ppaCaseWorkerHelp: string;
    ppaInvestigatorHelp: string;
    caseWorkerSearchTipsPdf: string;
    investigatorSearchTipsPdf: string;
    systemAdministratorHelpPdf: string;
    advancedFeaturesHelpPdf: string;
    gettingStartedHelpPdf: string;
    gettingMciStartedHelpPdf: string;
}

export const EXTERNAL_LINKS: IExternalLinks = {
    termsCondUrl: 'https://risk.lexisnexis.com/terms',
    privacyPolicyUrl: 'https://risk.lexisnexis.com/privacy-policy',
    copyrightUrl: 'https://risk.lexisnexis.com/copyright',
    supportPhone: '1-888-273-6623',
    supportEmail: 'gov.support@lexisnexisrisk.com',
    ppaSupportEmail: 'ppasupport@lexisnexisrisk.com',
    supportChat: 'https://lnrs.force.com/CustomerSupportHub/s/contactsupport/government-support',
    siteHelp: 'assets/pdf/Rin_Help.pdf',
    ppaHelp: 'assets/pdf/ProgramParticipationAnalyzer_SecurityAdministratorUserGuide.pdf',
    ppaCaseWorkerHelp: 'assets/pdf/ProgramParticipationAnalyzer_CaseWorkerUserGuide.pdf',
    ppaInvestigatorHelp: 'assets/pdf/ProgramParticipationAnalyzer_InvestigatorUserGuide.pdf',
    caseWorkerSearchTipsPdf: 'assets/pdf/Case_Worker_Search_Tips.pdf',
    investigatorSearchTipsPdf: 'assets/pdf/Investigator_Search_Tips.pdf',
    gettingMciStartedHelpPdf: 'assets/pdf/AmplifyID_Master_Person_Index_Get_Started.pdf',
    gettingStartedHelpPdf: 'assets/pdf/IRN_Get_Started.pdf',
    advancedFeaturesHelpPdf: 'assets/pdf/IRN_Features.pdf',
    systemAdministratorHelpPdf: 'assets/pdf/IRN_SystemAdministration.pdf'
};
