import { IMpiRecord } from '@services/backend/mci/search.config';
import { createActionGroup, emptyProps, props } from '@ngrx/store';


export const PendingTransactionsActions = createActionGroup({
    source: 'PENDING TRANSACTIONS',
    events: {
        'Load Pending Transactions': emptyProps(),
        'Set Pending Transactions': props<{ records: IMpiRecord[] }>()
    },
});