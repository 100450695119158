/**
 * Transforms raw value into displayable text
 * @param value
 *
 * Example:
 *  Provide 123456789
 *  Returns 123-45-6789
 */
export const formatSSN = (value: string) => {
    const onlyDigits = ('' + value).replace(/[^\d*]/g, '');
    const m = onlyDigits.match(/^(.{3})(.{2})(.{4})$/);
    return (!m) ? value : m[1] + '-' + m[2] + '-' + m[3];
}
