// The base model most future models will extend.

export class BaseModel {

    constructor() {}

    /**
     * Create new instance of this class type.
     * @returns {BaseModel}
     */
    public getInstance() {
        return new (this.constructor as typeof BaseModel)() as this;
    }

    /**
     * Fills the matching keys between this class and the provided object.
     * @param {object} jsonObject
     * @returns {this}
     */
    fromJson(jsonObject: object) {
        if (jsonObject) {
            Object.keys(jsonObject).forEach((key) => {
                if (this.hasOwnProperty(key)) {
                    this[key] = jsonObject[key];
                }
            });
        }
        return this;
    }
}
