import { Observable } from 'rxjs/internal/Observable';
import { Injectable, Inject } from '@angular/core';
import { routesPermissionsValue } from '@app/core/services/acl/config/acl-route-permissions-value';
import { ProductRouteModules } from '@models/enums/product-route-modules';
import { Nvp, NvpType } from '@models/user/nvp.model';
import { select, Store } from '@ngrx/store';
import { State } from '@app/store/states';
import { selectUserHasPermission, selectAuthUserPermissions, selectNvpByName } from '@app/store/selectors/auth.selectors';
import { first, map, take } from 'rxjs/operators';
import { of, combineLatest } from 'rxjs';
import { appGlossary } from '../configuration/glossary';

@Injectable({
    providedIn: 'root'
})
export class AclService {
    private permissions: string[] = [];

    constructor(
        private store: Store<State>
    ) {
    }

    /**
     * Returns true if the user allowed access to the provided route
     * @param route the route to test access for
     */
    hasRouteAccess(route: string): Observable<boolean> {
        const requirements = this.getRouteRequirements(route);
        if (!requirements) {
            return of(false);
        }
        const hasModuleAccess$ = this.hasModuleAccess(requirements.productRouteModule);
        const userHasPermission$ = this.store.pipe(
            select(selectUserHasPermission({ permissions: requirements.permissions })),
            take(1)
        );
        return combineLatest([hasModuleAccess$, userHasPermission$]).pipe(
            map(([hasModuleAccess, userHasPermission]) => (hasModuleAccess && userHasPermission))
        );
    }

    /**
     * Does the user have access to at least one of the provided permissions
     * @param permissions
     * @returns Promise<boolean>
     */
    hasPermission(permissions: string[]): boolean {
        this.store.pipe(select(selectAuthUserPermissions), first(value => !!value.length))
            .subscribe(allPermissions => this.permissions = allPermissions);
        return permissions.some(permission => this.permissions.includes(permission));
    }

    /**
     * Does the user have access to the specified module
     * @param productRouteModule
     * @return boolean whether the user has access or not
     */
    hasModuleAccess(productRouteModule: ProductRouteModules): Observable<boolean> {
        const requiredNvpName = this.getRouteModuleCorrespondingNvpName(productRouteModule);
        const companyHasRouteAccess$ = (requiredNvpName === false) ? of(true) : this.checkCompanyNvpAccess(requiredNvpName);
        const userHasRouteAccess$ = (requiredNvpName === false) ? of(true) : this.checkUserNvpAccess(requiredNvpName);
        return combineLatest([companyHasRouteAccess$, userHasRouteAccess$]).pipe(
            take(1),
            map(([companyHasRouteAccess, userHasRouteAccess]) => (userHasRouteAccess && companyHasRouteAccess))
        );
    }

    /**
     * Takes in the route to check and returns the requirements to access the route
     * @param routeString the route to get requirements for
     */
    public getRouteRequirements(routeString: string): { permissions: string[], productRouteModule: ProductRouteModules } {
        for (const index of routesPermissionsValue) {
            const queryStringRule = index.rule + '\\?.+'; // Match the route + query string
            if (index.hasOwnProperty('regex') && RegExp(index.rule).test(routeString)) {
                return { permissions: index.permissions, productRouteModule: index.module };
            } else if (index.rule === routeString) {
                return { permissions: index.permissions, productRouteModule: index.module };
            } else if (RegExp(queryStringRule).test(routeString)) {
                return { permissions: index.permissions, productRouteModule: index.module };
            }
        }
        return null;
    }

    /**
     * If route module is none, returns false, otherwise checks the glossary definitions for modules to return the nvp name
     * @param productRouteModule
     * @returns boolean | string
     */
    public getRouteModuleCorrespondingNvpName(productRouteModule: ProductRouteModules) {
        if (productRouteModule === ProductRouteModules.None) {
            return false;
        } else {
            return appGlossary.modules.find(module => module.label === productRouteModule).name;
        }
    }

    /**
     * Determines if the user has access to the required nvp
     * @param nvpName the nvp required to have access
     */
    checkCompanyNvpAccess(nvpName: string): Observable<boolean> {
        return this.store.pipe(
            select(selectNvpByName({ name: nvpName, type: NvpType.company })),
            take(1),
            map(companyNvp => (companyNvp instanceof Nvp && +companyNvp.value === 1))
        );
    }

    /**
     * Determines if the company has access to the required nvp
     * @param nvpName the nvp required to have access
     */
    checkUserNvpAccess(nvpName: string): Observable<boolean> {
        return this.store.pipe(
            select(selectNvpByName({ name: nvpName, type: NvpType.user })),
            take(1),
            map(userNvp => (userNvp instanceof Nvp && +userNvp.value === 1))
        );
    }
}
