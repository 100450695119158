import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { LogInterface } from '@services/log/log-interface';
import { LogType } from '@services/log/log-type.enum';
import { LogPriority } from '@services/log/log-priority.enum';
import { LogSettingsInterface } from '@services/log/log-settings.interface';
import { LogScope } from '@services/log/log-scope.enum';
import { AddLogRequestInterface } from '@services/log/add-log-request.interface';
import { LogSender } from '@services/log/log-sender.service';
import { UpdatedLogContextInterface } from '@services/log/updated-log-context.interface';
import { UserIdentityInterface } from '@models/user/identity.model';
import { ApplicationInsightsLogsService } from './application-insights/application-insights-logs.service';
import { AppInsightsSeverityLevelEnum } from './application-insights/app-insights-severity-level.enum';

@Injectable({
    providedIn: 'root'
})
export class AppLog implements LogInterface {
    private defaultSettings = {
        logPriority: LogPriority.DO_NOT_STORE,
        logScopes: [LogScope.FILE],
        addUrlToContext: true,
        includeFrontendSessionData: false // Typically handled by backend
    } as LogSettingsInterface;

    constructor(
        private logSender: LogSender,
        private appInsightService: ApplicationInsightsLogsService
    ) { }

    error(message: Error | string, settings: LogSettingsInterface, ...context: any[]): void {
        this.logMessage(message, context, settings, LogType.ERROR);
    }

    warning(message: string, settings: LogSettingsInterface, ...context: any[]): void {
        this.logMessage(message, context, settings, LogType.WARNING);
    }

    info(message: string, settings: LogSettingsInterface, ...context: any[]): void {
        this.logMessage(message, context, settings, LogType.INFO);
    }

    debug(message: string, settings: LogSettingsInterface, ...context: any[]): void {
        this.logMessage(message, context, settings, LogType.DEBUG);
    }

    log(message: string, settings: LogSettingsInterface, ...context: any[]): void {
        this.logMessage(message, context, settings, LogType.INFO);
    }

    notice(message: string, settings: LogSettingsInterface, ...context: any[]): void {
        this.logMessage(message, context, settings, LogType.NOTICE);
    }

    critical(message: string, settings: LogSettingsInterface, ...context: any[]): void {
        this.logMessage(message, context, settings, LogType.CRITICAL);
    }

    alert(message: string, settings: LogSettingsInterface, ...context: any[]): void {
        this.logMessage(message, context, settings, LogType.ALERT);
    }

    emergency(message: string, settings: LogSettingsInterface, ...context: any[]): void {
        this.logMessage(message, context, settings, LogType.EMERGENCY);
    }

    /**
     * Determines whether to log to the console, backend, or both and then calls the appropriate methods to handle these requests
     * @param message
     * @param context
     * @param settings
     * @param consoleType
     * @param logType
     * @param style
     */
    private logMessage(
        message: string | any,
        context: any,
        settings: LogSettingsInterface,
        logType: LogType
    ) {
        settings = { ...this.defaultSettings, ...settings } as LogSettingsInterface;
        if (environment.debug) {
            this.logToConsole(message, logType, context);
        }
        if (settings.logPriority !== LogPriority.DO_NOT_STORE && settings.logPriority >= environment.logPriorityCutoff) {
            context = this.addContext(context, settings);
            const requestData = { message, context, logType, logScopes: settings.logScopes } as AddLogRequestInterface;
            this.logToBackend(requestData);
            this.logAppInsights(requestData);
        }
    }

    private logAppInsights(requestData: AddLogRequestInterface) {
        if (!this.appInsightService.appInsights) {
            return;
        }
        const message = typeof requestData.message === 'string' ? new Error(requestData.message) : requestData.message;
        const severityLevel = AppInsightsSeverityLevelEnum[requestData.logType];
        this.appInsightService.logException(message, severityLevel);
    }

    /**
     * Sends the log to the console
     * @param message
     * @param consoleType
     * @param context
     */
    private logToConsole(message: string, consoleType: string, context: any) {
        console[consoleType](message, context);
    }

    /**
     * Sends the request to the backend for the log
     * @param requestData
     */
    private logToBackend(requestData: AddLogRequestInterface) {
        requestData.message = typeof requestData.message === 'string' ? requestData.message : requestData?.message?.message;
        this.logSender.addLog(requestData).subscribe();
    }

    /**
     * Includes additional data in context based on enabled settings.
     * @param context
     * @param settings
     */
    private addContext(context: any, settings: LogSettingsInterface): UpdatedLogContextInterface {
        const updatedContext = {
            providedContext: context
        } as UpdatedLogContextInterface;
        if (settings.addUrlToContext) {
            updatedContext.currentUrl = window.location.href;
        }
        if (settings.includeFrontendSessionData) {
            const identity = JSON.parse(sessionStorage.getItem('identity')) as UserIdentityInterface | null;
            let gcId = '';
            let userName = '';
            if (identity && identity.user && identity.user.nvps) {
                const gcIdNvp = identity.user.nvps.filter((nvp) => nvp.name === 'gc_id');
                gcId = (gcIdNvp.length > 0) ? gcIdNvp[0].value : '';
            }
            if (identity && identity.user && identity.user.userInfo) {
                userName = identity.user.userInfo.userName;
            }
            updatedContext.frontendSessionData = {
                userName,
                gcId
            };
        }
        return updatedContext;
    }
}
