import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AddLogRequestInterface } from '@services/log/add-log-request.interface';
import { HttpClient } from '@angular/common/http';
import { _getRequestOptions, _getRequestUrl } from '@services/backend/backend-utility-methods';
import { mpiConfig } from '@app/core/config/app-config.constants';

@Injectable({
    providedIn: 'root'
})
export class LogSender {

    constructor(
        protected http: HttpClient
    ) { }

    public addLog(requestData: AddLogRequestInterface): Observable<any> {
        return this.http.post(
            _getRequestUrl(mpiConfig.api.addLog),
            JSON.stringify(requestData),
            _getRequestOptions()
        );
    }
}
