import { uiKey, UIState } from './../reducers/ui.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const getUIState = createFeatureSelector<UIState>(uiKey);

export const selectUILoading = createSelector(getUIState, state => state.loading > 0);

export const selectUIIsModelOpen = createSelector(getUIState, state => state.isModalOpen);

export const selectUIModelConfimation = createSelector(getUIState, state => state.confirmation);
