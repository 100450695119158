import { Routes } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';
import { LoseChangesGuard } from '@core/guards/lose-changes.guard';
import { AclGuard } from '@app/core/guards/acl-guard.service';
import { ComplianceGuard } from '@core/guards/compliance.guard';
import { RouteActivationService } from './core/services/route-activation.service';

export const routes: Routes = [
    {
        path: 'sso/:token',
        loadComponent: () => import('./shared/layout/auth-empty-layout/auth-empty-layout.component').then(c => c.AuthEmptyLayoutComponent),
        children: [
            {
                path: '',
                loadComponent: () => import('app/containers/auth/sso/sso.component').then(c => c.SsoComponent)
            }
        ]
    },
    {
        path: 'mci',
        canActivate: [AuthGuard, AclGuard],
        loadComponent: () => import('./shared/layout/mci-layout/mci-layout.component').then(c => c.MciLayoutComponent),
        children: [
            {
                path: '',
                providers: [RouteActivationService],
                canActivate: [ComplianceGuard, LoseChangesGuard],
                loadChildren: () => import('app/containers/mci/mci-routing').then(m => m.routes),
            }
        ]
    },
    {
        path: 'product-use',
        loadComponent: () => import('./shared/layout/auth-empty-layout/auth-empty-layout.component').then(c => c.AuthEmptyLayoutComponent),
        children: [
            {
                path: '',
                providers: [RouteActivationService],
                canActivate: [LoseChangesGuard, AuthGuard],
                loadComponent: () => import('app/containers/compliance/compliance.component').then(c => c.ComplianceComponent),
            }
        ]
    },
    { path: '**', redirectTo: '/product-use', pathMatch: 'full' }
];

