export enum BreadcrumbsStepType {
    search = 'search',
    searchResult = 'results',
    merge = 'merge',
    split = 'split',
    worklist = 'worklist',
    pendingTransactions = 'pending-transactions',
    reports = 'reports',
    results = 'results',
    details = 'details',
    help = 'help',
    settings = 'settings'
}
