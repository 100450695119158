import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, enableProdMode, importProvidersFrom, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { httpInterceptorProviders } from './core/interceptors';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CustomErrorHandler } from './core/errors/custom-error-handler';
import { EnvLoaderService } from './core/services/configuration/env-loader.service';
import { lastValueFrom } from 'rxjs';
import { ACL_PERMISSIONS, ACL_PERMISSIONS_VALUE } from './core/services/acl/config/acl-permissions';
import { routes } from './app.routing';

// NgRx
import { provideStore } from '@ngrx/store';
import { metaReducers, reducers } from './store/states';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideEffects } from '@ngrx/effects';
import { effects } from './store';

function initializeConfig(envConfigService: EnvLoaderService) {
  return (): Promise<boolean> => lastValueFrom(envConfigService.load());
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes),
    provideStore(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    provideEffects(effects),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode(),
      autoPause: true,
      trace: false,
      traceLimit: 75,
    }),
    provideAnimations(),
    { provide: ACL_PERMISSIONS, useValue: ACL_PERMISSIONS_VALUE },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeConfig,
      deps: [EnvLoaderService],
      multi: true
    },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    ...httpInterceptorProviders
  ]
};
