import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { CustomModalComponent } from '@app/shared/ui/dialogs-modals-notifications/modal/contents/custom-modal.component';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, first, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ResponseMessage } from '../config/response-message.config';
import { API_CONFIG } from '../config/api-config';
import { Location } from '@angular/common';
import { selectAuthUserName } from '@app/store/selectors/auth.selectors';
import { Store, select } from '@ngrx/store';
import { State } from '@app/store/states';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    private readonly apiConfig = API_CONFIG;
    private readonly urlConfig = {
        urls: [
            'searchDetails',
            'usageSummary',
            'usageDetails',
        ],
        urlsWithRedirect: []
    };
    private userName: string;

    constructor(
        private store: Store<State>,
        private location: Location,
        public dialog: MatDialog
    ) {
        this.getUserName();
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((errorResponse: HttpErrorResponse) => {
                const hasRedirect = this.urlHasRedirect(errorResponse.url);
                if (this.isUrlIncluded(errorResponse.url) || hasRedirect) {
                    this.promptErrorModal(hasRedirect);
                }
                return throwError(() => new Error(errorResponse.error));
            })
        );
    }

    private getUserName(): void {
        if (!this.userName) {
            this.store.pipe(select(selectAuthUserName), first(value => !!value))
                .subscribe((userName: string) => this.userName = userName);
        }
    }

    private isUrlIncluded(url: string): boolean {
        return Object.values(this.urlConfig).some(urlConfigValues => {
            if (Array.isArray(urlConfigValues)) {
                return urlConfigValues.some(pathKey => this.isPathInUrl(url, pathKey));
            }
        });
    }

    private urlHasRedirect(url: string): boolean {
        return this.urlConfig.urlsWithRedirect.some(urlValue => {
            const apiString = this.apiConfig[urlValue];
            return url.includes(apiString);
        });
    }

    /**
     * Validates that the path is part of the URL
     *
     * @param url
     * @param pathKey
     * @param methodName must be an existing method in the class
     * @returns boolean
     */
    private isPathInUrl(url: string, pathKey: string, methodName?: string): boolean {
        try {
            const path = !methodName ? this.apiConfig[pathKey] : this.apiConfig[pathKey](this[methodName]());
            return url.includes(path);
        } catch {
            return false;
        }
    }

    private promptErrorModal(hasRedirect: boolean): void {
        const dialogRef = this.dialog.open(CustomModalComponent, {
            data: {
                type: 'warning',
                icon: 'fa-exclamation-circle',
                title: 'ERROR',
                content: ResponseMessage.ERROR_MESSAGES.UNEXPECTED_ERROR,
                yesLabel: 'Ok',
                hasNo: false
            }
        });
        dialogRef.afterClosed().pipe(take(1))
            .subscribe(() => {
                if (hasRedirect) {
                    this.location.back();
                }
        });
    }

    // Methods according global-error-config key
    // these methods will be called dynamically
    // -- ---------------------------------------
    private username(): string {
        if (this.userName && this.userName.lastIndexOf('@') !== -1) {
            this.userName = this.userName.substring(0, this.userName.lastIndexOf('@'));
        }
        return this.userName || '-';
    }

    // -- ---------------------------------------
}
