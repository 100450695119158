import { LowerCasePipe } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormatPhonePipe } from '@app/shared/utility/pipes/format-phone.pipe';

@Component({
  selector: 'app-identity-info-title',
  standalone: true,
  templateUrl: './identity-info-title.component.html',
  styleUrl: './identity-info-title.component.scss',
  imports: [
    LowerCasePipe,
    FormatPhonePipe
]
})
export class IdentityInfoTitleComponent {

  identityName = input.required<string>();
  mpiId = input.required<string>();
  highlightedName = input<boolean>(false);

}
