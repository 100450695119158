import { ModuleService } from '@app/core/services/module.service';
import { Injectable } from '@angular/core';
import { BreadcrumbsStep } from './breadcrumbs-step.interface';
import { BREADCRUMB_HOME } from '@app/shared/layout/main-layout/components/breadcrumbs/breadcrumbs.config';
import { ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { BreadcrumbsStepType } from './breadcrumbs-step-type.enum';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbsService {
    public static readonly BREADCRUMBS = 'breadcrumbs';

    protected steps: BreadcrumbsStep[];

    private stepClicked = new ReplaySubject<BreadcrumbsStep>(1);
    public stepClicked$ = this.stepClicked.asObservable();

    constructor(private router: Router) {
        this.steps = this.getSteps();
    }

    public getSteps(): BreadcrumbsStep[] {
        if (this.steps) {
            return this.steps;
        }
        const steps = JSON.parse(sessionStorage.getItem(BreadcrumbsService.BREADCRUMBS));
        return (steps) ? steps : this.getInitialBreadcrumbs();
    }

    public getLastStep(): BreadcrumbsStep {
        const steps = JSON.parse(sessionStorage.getItem(BreadcrumbsService.BREADCRUMBS)) || [];
        return steps[steps.length - 1];
    }

    public resetSteps() {
        this.steps = this.getInitialBreadcrumbs();
        this.updateStorage();
    }

    public addStep(step: BreadcrumbsStep) {
        const found = this.steps.find(current => current.label === step.label);
        if (!found) {
            this.steps.push(step);
        }
        this.processSteps(step);
        this.updateStorage();
    }

    public removeStep(step: BreadcrumbsStep): BreadcrumbsStep[] {
        let removed = [];
        const stepIndex = this.steps.findIndex(current => current.label === step.label);
        if (stepIndex >= 0) {
            removed = this.steps.splice(stepIndex, 1);
        }
        return removed;
    }

    public dispatchStep(step: BreadcrumbsStep) {
        this.stepClicked.next(step);
        if (step.params) {
            const params = this.createQueryParams(step.params);
            this.router.navigate([step.route], {
                queryParams: params
            });
        } else {
            this.router.navigate([step.route]);
        }
    }

    private removeFromStep(step: BreadcrumbsStep) {
        const stepIndex = this.steps.findIndex(current => current.label === step.label);
        if (stepIndex >= 0) {
            this.steps = this.steps.slice(0, stepIndex + 1);
        }
    }

    private processSteps(step: BreadcrumbsStep) {
        switch (step.type) {
            case BreadcrumbsStepType.search:
            case BreadcrumbsStepType.results:
            case BreadcrumbsStepType.help:
            case BreadcrumbsStepType.settings:
                this.removeFromStep(step);
                break;
            case BreadcrumbsStepType.details:
                const removedStep = this.removeStep(step);
                if (removedStep.length > 0) {
                    const latestStep = removedStep[0];
                    this.steps.push(latestStep);
                }
                break;
        }
    }

    private getInitialBreadcrumbs(): BreadcrumbsStep[] {
        return [BREADCRUMB_HOME];
    }

    private updateStorage() {
        sessionStorage.setItem(BreadcrumbsService.BREADCRUMBS, JSON.stringify(this.steps));
    }

    private createQueryParams(paramsObj: any): any {
        const params = {};
        for (const key in paramsObj) {
            if (paramsObj.hasOwnProperty(key)) {
                params[key] = paramsObj[key];
            }
        }
        return params;
    }
}
