import { HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { mpiConfig } from '@app/core/config/app-config.constants';

export function _getRequestUrl(url: string) {
    const tokenStringIfNeeded = url.match('index.php')
        ? `?token=${sessionStorage.getItem(AuthenticationService.authTokenStoreKey)}` : '';
    return `${mpiConfig.api.baseUrl}${url}${tokenStringIfNeeded}`;
}

export function _getRequestOptions() {
    const header = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem(AuthenticationService.authTokenStoreKey)}`
    };
    const headers = new HttpHeaders(header);
    return { headers, observe: 'response' } as any;
}
