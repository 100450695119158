import { LogPriority } from './../log/log-priority.enum';
import { logoutSuccess } from '@app/store/actions';
import { map } from 'rxjs/operators';
import { Injectable, isDevMode } from '@angular/core';
import { Observable, lastValueFrom, of } from 'rxjs';
import { mpiConfig } from '@app/core/config/app-config.constants';
import { HttpClient } from '@angular/common/http';
import { AppLog } from '@app/core/services/log/app-log.service';
import { BackendService } from '@app/core/services/backend/backend.service';
import { LoginResponseInterface } from '@app/core/services/backend/interfaces/login-response.interface';
import { State } from '@app/store/states';
import { Store } from '@ngrx/store';
import { environment } from '@environment';
import { ComplianceInterface } from '@app/containers/compliance/compliance.config';
import moment from 'moment';


@Injectable({
    providedIn: 'root'
})
export class AuthenticationService extends BackendService {

    static readonly authTimestampStoreKey = 'auth-timestamp';
    static readonly authTokenStoreKey = 'token';

    constructor(
        protected http: HttpClient,
        protected logger: AppLog,
        protected store: Store<State>
    ) {
        super(http, logger, store);
    }

    /**
     * Sets the compliance information for the user
     *
     * @param compliances - The compliance data to be set
     * @returns An observable that emits the processed response from the API
     */
    setCompliances(compliances: ComplianceInterface) {
        return this.doPut(mpiConfig.api.compliances, JSON.stringify(compliances))
            .pipe(map((response: any) => this.processResponse(response)));
    }

    /**
     * Logs out the user by sending a DELETE request to the logout API endpoint
     * On success, it redirects the user to the login page
     */
    logout(): void {
        this.doDelete(mpiConfig.api.logout)
            .subscribe({
                next: () => this.goToLogin(),
                error: (error) => this.handleErrorResponse(error)
            });
    }

    /**
     * Clears session data and dispatches a logout success action
     */
    clearData(): void {
        sessionStorage.clear();
        this.store.dispatch(logoutSuccess());
    }

    /**
     * Renews the user's session
     */
    renew(): Observable<any> {
        return this.doPut(mpiConfig.api.renew, '{}')
            .pipe(map((res: any) => this.processResponse(res)));
    }

    /**
     * Logs in the user using a Single Sign-On (SSO) token
     * If in development mode, it returns a mock response
     * Otherwise, it sends a GET request to the SSO API endpoint and processes the response
     *
     * @param ssoToken - The SSO token used for authentication
     */
    ssoLogin(ssoToken: string) {
        if (isDevMode()) {
            return of(this.processResponse(this.buildDevAuthResponse()));
        }

        return this.doGet(mpiConfig.api.sso(ssoToken))
            .pipe(map((response: any) => this.processResponse(response)));
    }

    /**
     * Switches the user's login session to a different module
     *
     * @param moduleName - The name of the module to switch to
     */
    switchLogin(moduleName: string): Promise<any> {
        return lastValueFrom(this.doGet(mpiConfig.api.ssoSwitchSession(moduleName)));
    }

    /**
     * Checks if the user's session has expired based on the stored timestamp and expiration time
     *
     * @returns A boolean indicating whether the session has expired
     */
    isExpired(): boolean {
        const expirationTime = mpiConfig.autoRenewSession.expirationTime;
        const timestamp: number = Number(sessionStorage.getItem(AuthenticationService.authTimestampStoreKey));
        const m = moment.unix(timestamp).add(expirationTime, 'seconds');
        return !timestamp || m.isSameOrBefore(moment());
    }

    /**
     * Handles error responses from the backend
     * Logs the error and redirects the user to the login page
     *
     * @param error - The error response from the backend
     */
    private handleErrorResponse(error: any): void {
        this.logger.info('Logout Service: Failed backend logout',
            { logPriority: LogPriority.MEDIUM },
            { error }
        );
        this.goToLogin();
    }

    /**
     * Redirects the user to the login page after clearing session data
     */
    private goToLogin(): void {
        this.clearData();
        window.location.href = `${environment.baseModuleUrl}/${mpiConfig.auth.logoutRedirect}`;
    }

    /**
     * Processes the response from the API
     * Stores the JWT token and timestamp in session storage if available
     *
     * @param response - The response from the API
     * @returns The processed response as a LoginResponseInterface
     */
    private processResponse(response): LoginResponseInterface {
        const data = response?.data;
        const timestamp = response?.body?.request?.timestamp;
        const token = data?.jwt;

        if (token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            sessionStorage.setItem(AuthenticationService.authTimestampStoreKey, timestamp);
            sessionStorage.setItem(AuthenticationService.authTokenStoreKey, token);
        }
        return { status: response.status, body: response.body };
    }

    /**
     * Builds a mock authentication response for development mode
     *
     * @returns A mock response object with a JWT token and timestamp
     */
    private buildDevAuthResponse(): any {
        return {
            "body": {
                "request": {
                    "timestamp": new Date().getTime()
                }
            },
            "data": {
                "jwt": "DEV_MODE_TOKEN"
            }
        }
    }
}
