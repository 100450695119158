<div class="dialog-container">
    <button class="dialog-close" [mat-dialog-close]="true" aria-label="Close">
        <i class="fa fa-times" aria-hidden="true"></i>
    </button>
    <h1 mat-dialog-title>{{title}}</h1>

    <mat-dialog-content>
        <div class="wrapper">

            <p>{{subTitle}}</p>

            <div class="main-container">

                @for (index of [compareIndex.first, compareIndex.second]; track index) {
                    <div [class]="'col' + index">

                        @if (!isPublicRecord) {
                            <div class="identity">
                                <span class="fs-mask">
                                    {{compareData[index] | formatDetailsName: true}}
                                </span>
                                <span class="pl-1 fs-mask">
                                    {{compareData[index]?.dateAdded | date: 'MM/dd/y'}}
                                </span>
                            </div>
                        } @else {
                            <div class="fa-stack fa-2x icon-wrapper">

                                @if (index === compareIndex.first) {
                                    <span class="icon-c">C</span>
                                } @else {
                                    <i aria-hidden="true" class="fa-stack-1x element-icon far fa-balance-scale"></i>
                                }

                            </div>

                            <p class="mb-0">{{(index === compareIndex.first) ? 'Contributed Data' : 'Public Records'}}</p>

                            <app-identity-info-title
                                [identityName]="compareData[index] | formatDetailsName: true"
                                [highlightedName]="isNameDiff"
                                [mpiId]="compareData[index]?.mpiId ? compareData[index]?.mpiId : compareData[0]?.mpiId">
                            </app-identity-info-title>
                        }

                        @for (item of data[index]; track $index) {

                            @if (item.showRow !== false) {
                                <div class="data-list">
                                    <div class="data-label" [class.highlighted]="item.highlighted">
                                        {{item?.label}}:
                                    </div>
                                    <div class="data-value fs-mask" [class.highlighted]="item.highlighted">
                                        {{ item?.value | noAvailableValue }}
                                    </div>
                                </div>
                            }

                        }

                    </div>
                }
            </div>

        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button class="btn btn-primary" [mat-dialog-close]="true" aria-label="Close">Close</button>
    </mat-dialog-actions>
</div>
