import { Action, createReducer, on } from '@ngrx/store';
import { ApiResponseReportsTypes } from '@app/core/services/backend/interfaces/reports-response.interface';
import { ReportsActions } from '../actions/reports.actions';


export const reportsKey = 'reports';

export interface ReportsState {
    reports: ApiResponseReportsTypes;
}

export const initialReportsState: ReportsState = {
    reports: null,
};

export const reportsReducers = createReducer(
    initialReportsState,
    on(ReportsActions.setReports, (state, { reports }) => ({ ...state, reports })),
    on(ReportsActions.clearReports, (state) => ({ ...state, reports: null })),
);

export function reportsReducer(state: ReportsState | undefined, action: Action): ReportsState {
    return reportsReducers(state, action);
}
