<div class="identity-info-wrap">
    <div class="row-container">
        <div class="name-container mr-4">
            <p class="mat-card-subtitle fs-mask  mb-0">NAME</p>
            <p class="mat-card-title fs-mask" [class.highlighted]="highlightedName()">{{ identityName() }}</p>
        </div>
    </div>

    <div class="row-container">
        <div class="mpi-id-container mr-4">
            <p class="mat-card-subtitle fs-mask mb-0">MPI ID</p>
            <p class="mat-card-title fs-mask">
                {{ mpiId() || '-' }}
            </p>
        </div>
    </div>
</div>
