import { Pipe, PipeTransform } from '@angular/core';
/*
 * Add a text when the value is empty or is included in the list of possible values
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | noAvailableValue:noAvailableText: [0, '']
*/
@Pipe({
  name: 'noAvailableValue',
  standalone: true
})
export class NoAvailablePipe implements PipeTransform {
  public static defaultNoAvailableText = 'N/A';
  public static emptyValues = [0, '', '0', null, undefined];
  
  transform(value: any, availableText?: string, listOfEmptyValues?: string[]): number {
    const emptyValues = listOfEmptyValues || NoAvailablePipe.emptyValues;
    const text = availableText || NoAvailablePipe.defaultNoAvailableText;
    return emptyValues.includes(value) ? text : value;
  }
}
