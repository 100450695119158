import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';
import { IMpiRecord } from '@services/backend/mci/search.config';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromAction from '../actions/worklist.actions';


export const worklistKey = 'worklist';

export const identityAdapter: EntityAdapter<IMpiRecord> = createEntityAdapter<IMpiRecord>({
    selectId: (identity: IMpiRecord) => identity.mpiRecordId
});
export interface IdentityState extends EntityState<IMpiRecord> {
    idsSelected: number[];
    identitiesLoaded: boolean;
}

export const transactionAdapter: EntityAdapter<IMpiRecord> = createEntityAdapter<IMpiRecord>({
    selectId: (identity: IMpiRecord) => identity.mpiRecordId
});
export interface TransactionState extends EntityState<IMpiRecord> {
    idsSelected: number[];
    transactionsLoaded: boolean;
}

export interface WorklistState {
    identities: IdentityState;
    transactions: TransactionState;
}

export const worklistAdapter: WorklistState = {
    identities: identityAdapter.getInitialState({
        idsSelected: [],
        identitiesLoaded: false
    }),
    transactions: transactionAdapter.getInitialState({
        idsSelected: [],
        transactionsLoaded: false
    })
};

export const worklistReducers = createReducer(
    worklistAdapter,
    // Identities
    on(fromAction.WorklistActions.loadIdentitiesSuccess, (state, { records }) => ({
        ...state,
        identities: identityAdapter.setAll(records, { ...state.identities, identitiesLoaded: true })
    })),
    on(fromAction.WorklistActions.updateIdentityIdsSelected, (state, { id }) => ({
        ...state,
        identities: {
            ...state.identities, idsSelected: state.identities.idsSelected.includes(id)
                ? state.identities.idsSelected.filter(currentId => currentId !== id)
                : [...state.identities.idsSelected, id]
        }
    })),
    on(fromAction.WorklistActions.clearIdentityIdsSelected, (state) => ({
        ...state,
        identities: { ...state.identities, idsSelected: [] }
    })),
    on(fromAction.WorklistActions.loadIdentitiesFail, (state) => ({
        ...state,
        identities: identityAdapter.removeAll({ ...state.identities, idsSelected: [], identitiesLoaded: false })
    })),
    on(fromAction.WorklistActions.setIdentities, (state, { identities }) => ({
        ...state, identities: identityAdapter.setMany(identities, state.identities)
    })),
    on(fromAction.WorklistActions.removeOneIdentity, (state, { mpiRecordId }) => ({
        ...state,
        identities: identityAdapter.removeOne(
            mpiRecordId,
            { ...state.identities, idsSelected: [...state.identities.idsSelected.filter(id => id !== mpiRecordId)] }
        )
    })),
    on(fromAction.WorklistActions.removeIdentities, (state, { mpiRecordIds }) => ({
        ...state,
        identities: identityAdapter.removeMany(
            mpiRecordIds,
            { ...state.identities, idsSelected: [...state.identities.idsSelected.filter(id => !mpiRecordIds.includes(id))] }
        )
    })),
    // Transactions
    on(fromAction.WorklistActions.loadTransactionsSuccess, (state, { records }) => ({
        ...state,
        transactions: transactionAdapter.setAll(records, { ...state.transactions, transactionsLoaded: true })
    })),
    on(fromAction.WorklistActions.updateTransactionIdsSelected, (state, { id }) => ({
        ...state,
        transactions: {
            ...state.transactions, idsSelected: state.transactions.idsSelected.includes(id)
                ? state.transactions.idsSelected.filter(currentId => currentId !== id)
                : [...state.transactions.idsSelected, id]
        }
    })),
    on(fromAction.WorklistActions.clearTransactionIdsSelected, (state) => ({
        ...state,
        transactions: { ...state.transactions, idsSelected: [] }
    })),
    on(fromAction.WorklistActions.loadTransactionsFail, (state) => ({
        ...state,
        transactions: transactionAdapter.removeAll({ ...state.transactions, idsSelected: [], transactionsLoaded: false })
    })),
    on(fromAction.WorklistActions.setTransactions, (state, { transactions }) => ({
        ...state,
        transactions: transactionAdapter.setMany(transactions, state.transactions)
    })),
    on(fromAction.WorklistActions.removeOneTransaction, (state, { mpiRecordId }) => ({
        ...state,
        transactions: transactionAdapter.removeOne(
            mpiRecordId,
            { ...state.transactions, idsSelected: [...state.transactions.idsSelected.filter(id => id !== mpiRecordId)] }
        )
    })),
    on(fromAction.WorklistActions.removeTransactions, (state, { mpiRecordIds }) => ({
        ...state,
        transactions: transactionAdapter.removeMany(
            mpiRecordIds,
            { ...state.transactions, idsSelected: [...state.transactions.idsSelected.filter(id => !mpiRecordIds.includes(id))] }
        )
    })),
);

export function worklistReducer(state: WorklistState | undefined, action: Action): WorklistState {
    return worklistReducers(state, action);
}
