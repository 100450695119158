/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ComplianceInterceptor } from './compliance-interceptor';
import { ExternalAppRedirectInterceptor } from './external-apps-redirect-interceptor';
import { HttpErrorInterceptor } from './http-error-interceptor';
import { RequestInterceptor } from './request-interceptor';


/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: ComplianceInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ExternalAppRedirectInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
];
