import { AuthenticationService } from '@app/core/services/backend/authentication.service';
import { selectAuthIdentity } from '@app/store/selectors/auth.selectors';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { AppLog } from '@services';
import { LogPriority } from '@services/log/log-priority.enum';
import { Store } from '@ngrx/store';
import { logout } from '@app/store/actions';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { State } from '@app/store/states';


export const AuthGuard: CanActivateChildFn | CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store<State> = inject(Store),
    logger: AppLog = inject(AppLog),
    authService: AuthenticationService = inject(AuthenticationService)
): Observable<boolean> => {
    return store.select(selectAuthIdentity)
        .pipe(
            first(value => !!value),
            map(() => {
                const access = (!authService.isExpired());
                if (!access) {
                    logger.info(
                        'AuthGuard: User is not authenticated. Redirecting to logout.',
                        { logPriority: LogPriority.MEDIUM }
                    );
                    store.dispatch(logout());
                }
                return access;
            })
        );
}
