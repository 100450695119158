import { TitleCasePipe } from '@angular/common';

/**
 * Transforms a string to have only the first character of each word as uppercased
 * @param str
 *
 * Example:
 *  Provide joe smith, JOE SMITH, or jOe sMItH
 *  Returns Joe Smith
 */
export const toTitleCase = (str: string) => {
    return new TitleCasePipe().transform(str || '');
}
