import { ErrorHandler, Injectable } from '@angular/core';
import { AppLog } from '@services';
import { LogPriority } from '@services/log/log-priority.enum';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {

    constructor(private appLog: AppLog) { }

    /**
     * Send the error to the backend to be logged.
     * @param error
     */
    handleError(error) {
        this.appLog.error(error, { logPriority: LogPriority.HIGH }, { stack: error.stack });
    }
}
