import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CustomModalComponent } from '@app/shared/ui/dialogs-modals-notifications/modal/contents/custom-modal.component';
import { AppLog } from '@app/core/services/log/app-log.service';
import { LogPriority } from '@app/core/services/log/log-priority.enum';
import * as fromActions from '@app/store/actions';

@Injectable()
export class UIEffect {

    constructor(
        private actions$: Actions,
        public dialog: MatDialog,
        private logger: AppLog,
    ) {
    }

    showErrorMessage$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.showErrorMessage),
        exhaustMap(({ message, title, width, error }) => {
            title = title ? title : 'ERROR';
            width = width ? width : 360;

            if (error) {
                this.logger.error(message, { logPriority: LogPriority.HIGH }, error);
            }

            const dialogRef = this.dialog.open(CustomModalComponent, {
                width: `${width}px`,
                data: {
                    type: 'warning',
                    icon: 'fa-exclamation-circle',
                    hasNo: false,
                    yesLabel: 'OK',
                    title,
                    content: message
                }
            });
            return dialogRef.afterClosed().pipe(
                take(1),
                map(() => fromActions.closeModal())
            );
        }),
    ));

    showConfirmMessage$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.showConfirmMessage),
        exhaustMap(({ message, title, width }) => {
            title = title ? title : 'CONFIRMATION';
            width = width ? width : 380;
            const dialogRef = this.dialog.open(CustomModalComponent, {
                width: `${width}px`,
                data: {
                    type: 'warning',
                    icon: 'fa-exclamation-circle',
                    title,
                    content: message
                }
            });
            return dialogRef.afterClosed().pipe(
                take(1),
                map(confirmed => fromActions.confirmationModalSuccess({ confirmed }))
            );
        })
    ));
}
