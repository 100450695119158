import { Component, Inject } from '@angular/core';
import { IModalData } from '@app/shared/ui/dialogs-modals-notifications/modal/modal-data.interface';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { SanitizeHtmlPipe } from '@app/shared/utility/pipes/sanitize-html.pipe';


@Component({
    selector: 'app-custom-modal',
    templateUrl: './custom-modal.component.html',
    styleUrls: ['./custom-modal.component.scss'],
    standalone: true,
    imports: [MatDialogModule, SanitizeHtmlPipe],
})
export class CustomModalComponent {

    title: string;
    content: string;
    type = 'warning';
    icon = 'fa-exclamation-circle';
    yesLabel = 'YES';
    hasYes = true;
    noLabel = 'NO';
    hasNo = true;

    constructor(
        public dialogRef: MatDialogRef<CustomModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IModalData
    ) {
        this.title = data.title;
        this.content = data.content;

        this.type = (data.type !== undefined) ? data.type : this.type;
        this.icon = (data.icon !== undefined) ? data.icon : this.icon;
        this.yesLabel = (data.yesLabel !== undefined) ? data.yesLabel : this.yesLabel;
        this.hasYes = (data.hasYes !== undefined) ? data.hasYes : this.hasYes;
        this.noLabel = (data.noLabel !== undefined) ? data.noLabel : this.noLabel;
        this.hasNo = (data.hasNo !== undefined) ? data.hasNo : this.hasNo;
    }

}
