import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CompareRecordsService } from '@services/backend/mci/compare-records.service';
import { CommonModule } from '@angular/common';
import { NoAvailablePipe } from '@app/shared/utility/pipes/no-available-value.pipe';
import { FormatDetailsNamePipe } from '@app/shared/utility/pipes/format-details-name.pipe';
import { IdentityInfoTitleComponent } from '@app/shared/components/identity-info-title/identity-info-title.component';
import * as fromConfig from './compare-records-modal.config';


@Component({
    selector: 'app-mci-compare-records-modal',
    templateUrl: './compare-records-modal.component.html',
    styleUrls: ['./compare-records-modal.component.scss'],
    standalone: true,
    providers: [CompareRecordsService],
    imports: [
        CommonModule,
        NoAvailablePipe,
        FormatDetailsNamePipe,
        MatDialogModule,
        IdentityInfoTitleComponent
    ]
})
export class MciCompareRecordsModalComponent implements OnInit {

    isPublicRecord = false;
    compareIndex = fromConfig.CompareIndex;
    title: string;
    subTitle: string;
    compareData: any[] = [];
    isNameDiff = false;
    data: fromConfig.ResultCompareRecordsInterface = {
        [fromConfig.CompareIndex.first]: [],
        [fromConfig.CompareIndex.second]: []
    };

    constructor(
        private dialogRef: MatDialogRef<MciCompareRecordsModalComponent>,
        private compareService: CompareRecordsService,
        @Inject(MAT_DIALOG_DATA) public mdData: fromConfig.CompareRecordsDataInterface
    ) {
        this.dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.isPublicRecord = this.mdData.isPublicRecord === true;
        this.compareData = this.mdData.records;
        this.setTitles();

        this.compareNameMatch();
        this.data = this.compareService.setHighlighted(
            this.compareData[fromConfig.CompareIndex.first],
            this.compareData[fromConfig.CompareIndex.second],
            this.isPublicRecord,
            !this.isPublicRecord
        );
    }

    private setTitles(): void {
        const key = (this.isPublicRecord) ? fromConfig.titleKey.publicRecord : fromConfig.titleKey.commonRecord;
        this.title = fromConfig.title[key];
        this.subTitle = fromConfig.subTitle[key];
    }

    private compareNameMatch() {
        if (this.isPublicRecord) {
            this.isNameDiff = this.compareService.isSameName(
                this.compareData[fromConfig.CompareIndex.first],
                this.compareData[fromConfig.CompareIndex.second]
            );
        }
    }
}
