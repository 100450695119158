import { isEmpty, get } from 'lodash';
import { NameInterfaceLowercase } from '@app/core/data-models/common/name-lowercase.interface';
import { toTitleCase } from './to-title-case.function';

/**
 * Get the full name
 * @param {Object} name
 *
 */
export function formatName(name: NameInterfaceLowercase, reverseOrder = false) {
    if (isEmpty(name)) {
        return '';
    }

    const prefix = !isEmpty(get(name, 'prefix', '')) ? toTitleCase(name.prefix) : '';
    const first = !isEmpty(get(name, 'first', '')) ? toTitleCase(name.first) : '';
    const middle = !isEmpty(get(name, 'middle', '')) ? toTitleCase(name.middle) : '';
    const last = !isEmpty(get(name, 'last', '')) ? toTitleCase(name.last) : '';
    const suffix = !isEmpty(get(name, 'suffix', '')) ? name.suffix : '';

    const result = reverseOrder
        ? `${last} ${suffix}, ${prefix} ${first} ${middle}`
        : `${prefix} ${first} ${middle} ${last} ${suffix}`;

    return result
        .replace(' ,', ',')
        .replace(/\s+/g, ' ')
        .trim();
}
