export const AppPermissions = {
    identity: {
        viewModule: 'FRAUDGOV_ENABLE_IDENTITY_RISK',
    },
    ppa: {
        viewModule: 'FRAUDGOV_ENABLE_PPA'
    },
    mci: {
        viewModule: 'MCIGOV_ENABLE_MCI',
        search: 'MCIGOV_SEARCH',
        merge: 'MCIGOV_MERGE',
        split: 'MCIGOV_SPLIT',
        add: 'MCIGOV_ADD',
        update: 'MCIGOV_UPDATE',
        bestIdentity: 'MCIGOV_ENABLE_MCIBestIdentity',
        household: 'MCIGOV_ENABLE_MCIHouseholdID',
        householdInsights: 'MCIGOV_ENABLE_MCIHouseholdInsights',
        profileBoostertHealth: 'MCIGOV_ENABLE_MCIProfileBoosterGovtHealth',
        profileBoostertServices: 'MCIGOV_ENABLE_MCIProfileBoosterGovtServices',
        mortalityInsights: 'MCIGOV_ENABLE_MCIMortalityInsights'
    },
    announcements: {
        viewModule: 'FRAUDGOV_UPDATE_ANNOUNCEMENTS',
        mci: 'FRAUDGOV_ANNOUNCEMENTS_MCI',
    },
    roles: {
        agencyAdmin: 'FRAUDGOV_AGENCY_ADMIN',
        securityAdmin: 'FRAUDGOV_SECURITY_ADMINISTRATOR',
        investigator: 'FRAUDGOV_INVESTIGATOR',
        assessor: 'FRAUDGOV_IDENTITY_ASSESSOR',
        ppaCaseWorker: 'FRAUDGOV_PPA_CASE_WORKER',
        ppaInvestigator: 'FRAUDGOV_PPA_INVESTIGATOR',
        ppaDhsAdmin: 'FRAUDGOV_DHSADMIN_USER',
        announcements: 'FRAUDGOV_INTERNAL_ADMIN',
    },
};
