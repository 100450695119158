import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { RouteActivationService } from '../services/route-activation.service';


export const LoseChangesGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    checkActivation: RouteActivationService = inject(RouteActivationService)
): boolean => {
    return checkActivation.beforeActive(state.url);
}