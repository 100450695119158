import { ProductRouteModules } from '@models/enums/product-route-modules';
import { AppPermissions } from './../acl/config/acl-permissions-constants';

export interface GlossaryConfigInterface {
    modules: any[];
    suffix: IGlossaryData[];
    states: IGlossaryData[];
    timezones: IGlossaryData[];
    status: IGlossaryData[];
}

export interface IGlossaryData {
    value: string | null;
    label: string;
    valueString?: string;
}

export const appGlossary: GlossaryConfigInterface = {
    modules: [
        { id: 1, value: 1, label: ProductRouteModules.Identity, name: AppPermissions.identity.viewModule },
        { id: 2, value: 2, label: ProductRouteModules.PPA, name: AppPermissions.ppa.viewModule },
        { id: 3, value: 3, label: ProductRouteModules.MCI, name: AppPermissions.mci.viewModule },
    ],
    suffix: [
        { value: null, label: 'Select' },
        { value: 'Sr', label: 'Senior' },
        { value: 'Jr', label: 'Junior' }
    ],
    states: [
        { value: null, label: 'Select' },
        { value: 'AL', label: 'AL (Alabama)' },
        { value: 'AK', label: 'AK (Alaska)' },
        { value: 'AZ', label: 'AZ (Arizona)' },
        { value: 'AR', label: 'AR (Arkansas)' },
        { value: 'CA', label: 'CA (California)' },
        { value: 'CO', label: 'CO (Colorado)' },
        { value: 'CT', label: 'CT (Connecticut)' },
        { value: 'DC', label: 'DC (District of Columbia)' },
        { value: 'DE', label: 'DE (Delaware)' },
        { value: 'FL', label: 'FL (Florida)' },
        { value: 'GA', label: 'GA (Georgia)' },
        { value: 'GU', label: 'GU (Guam)' },
        { value: 'HI', label: 'HI (Hawaii)' },
        { value: 'ID', label: 'ID (Idaho)' },
        { value: 'IL', label: 'IL (Illinois)' },
        { value: 'IN', label: 'IN (Indiana)' },
        { value: 'IA', label: 'IA (Iowa)' },
        { value: 'KS', label: 'KS (Kansas)' },
        { value: 'KY', label: 'KY (Kentucky)' },
        { value: 'LA', label: 'LA (Louisiana)' },
        { value: 'ME', label: 'ME (Maine)' },
        { value: 'MD', label: 'MD (Maryland)' },
        { value: 'MA', label: 'MA (Massachusetts)' },
        { value: 'MI', label: 'MI (Michigan)' },
        { value: 'MN', label: 'MN (Minnesota)' },
        { value: 'MS', label: 'MS (Mississippi)' },
        { value: 'MO', label: 'MO (Missouri)' },
        { value: 'MT', label: 'MT (Montana)' },
        { value: 'NE', label: 'NE (Nebraska)' },
        { value: 'NV', label: 'NV (Nevada)' },
        { value: 'NH', label: 'NH (New Hampshire)' },
        { value: 'NJ', label: 'NJ (New Jersey)' },
        { value: 'NM', label: 'NM (New Mexico)' },
        { value: 'NY', label: 'NY (New York)' },
        { value: 'NC', label: 'NC (North Carolina)' },
        { value: 'ND', label: 'ND (North Dakota)' },
        { value: 'MP', label: 'MP (Northern Mariana Islands)' },
        { value: 'OH', label: 'OH (Ohio)' },
        { value: 'OK', label: 'OK (Oklahoma)' },
        { value: 'OR', label: 'OR (Oregon)' },
        { value: 'PA', label: 'PA (Pennsylvania)' },
        { value: 'PR', label: 'PR (Puerto Rico)' },
        { value: 'RI', label: 'RI (Rhode Island)' },
        { value: 'SC', label: 'SC (South Carolina)' },
        { value: 'SD', label: 'SD (South Dakota)' },
        { value: 'TN', label: 'TN (Tennessee)' },
        { value: 'TX', label: 'TX (Texas)' },
        { value: 'VI', label: 'VI (U.S. Virgin Islands)' },
        { value: 'UT', label: 'UT (Utah)' },
        { value: 'VT', label: 'VT (Vermont)' },
        { value: 'VA', label: 'VA (Virginia)' },
        { value: 'WA', label: 'WA (Washington)' },
        { value: 'WV', label: 'WV (West Virginia)' },
        { value: 'WI', label: 'WI (Wisconsin)' },
        { value: 'WY', label: 'WY (Wyoming)' }
    ],
    timezones: [
        { value: null, label: 'Select' },
        { value: 'UTC', label: 'Universal Time (UTC/GMT)' },
        { value: 'America/Halifax', label: 'Atlantic Time (UTC-04:00)' },
        { value: 'America/New_York', label: 'Eastern Time (UTC-05:00))' },
        { value: 'America/Detroit', label: 'Central Time (UTC-06:00)' },
        { value: 'America/Denver', label: 'Mountain Time (UTC-07:00)' },
        { value: 'America/Los_Angeles', label: 'Pacific Time (UTC-08:00)' },
        { value: 'America/Anchorage', label: 'Alaska Time (UTC-09:00)' },
        { value: 'America/Honolulu', label: 'Hawaii-Aleutian Time (UTC-10:00)' },
        { value: 'Pacific/Pago_Pago', label: 'Samoa Time (UTC-11:00)' },
        { value: 'Pacific/Guam', label: 'Chamorro Time (UTC+10:00)' }
    ],
    status: [
        { value: null, valueString: '', label: 'Select' },
        { value: 'Enabled', valueString: 'Enabled', label: 'Active' },
        { value: 'Disabled', valueString: 'Disabled', label: 'Inactive' }
    ]
};

