import { AuthEffects } from './auth.effects';
import { PendingTransactionsEffect } from './pending-transactions.effects';
import { RecordsEffect } from './records.effects';
import { ReportsEffect } from './reports.effects';
import { UIEffect } from './ui.effects';
import { WorklistEffects } from './worklist.effects';

export const effects: any[] = [
    AuthEffects,
    PendingTransactionsEffect,
    RecordsEffect,
    ReportsEffect,
    UIEffect,
    WorklistEffects
];
