import { BreadcrumbsService } from '@app/core/services/breadcrumbs/breadcrumbs.service';
import { Injectable, NgZone } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { A11yCATInteractiveType } from 'environments/environment.default';

@Injectable()
export class AccessibilityService {

    navEnd: Observable<NavigationEnd>;

    constructor(
        router: Router,
        private zone: NgZone,
        private breadcrumbsService: BreadcrumbsService
    ) {
        this.navEnd = router.events.pipe(
            filter(evt => evt instanceof NavigationEnd)
        ) as Observable<NavigationEnd>;
    }

    start() {
        if (environment.production !== true && environment?.a11yCAT) {
            this.loadA11yCAT().subscribe(loaded => {
                if (loaded) {
                    setTimeout(() => this.configureA11yCAT(), 1000);
                    this.trigger();
                }
            });
        }
    }

    private loadA11yCAT() {
        return new Observable<boolean>(observer => {
            this.zone.runOutsideAngular(() => {
                try {
                    const script = document.createElement('script');
                    script.src = environment.a11yCAT.cdn;
                    script.async = false;
                    document.body.append(script);
                    observer.next(true);
                } catch (error) {
                    observer.error(error);
                }
            });
        });
    }

    private configureA11yCAT() {
        this.zone.runOutsideAngular(() => {
            if (typeof window['a11yCAT'] === 'object') {
                window['a11yCAT'].appName = environment.a11yCAT.appName;
                window['a11yCAT'].appKey = environment.a11yCAT.appKey;
                window['a11yCAT'].interactive = environment.a11yCAT.interactive ?? A11yCATInteractiveType.auto;
            }
        });
    }

    private trigger() {
        this.navEnd.subscribe(() => {
            this.zone.runOutsideAngular(() =>
                setTimeout(() => {
                    const lastStep = this.breadcrumbsService.getLastStep();
                    if (lastStep) {
                        window['a11yCAT'].checkA11y('App Page', lastStep.label);
                    }
                }, 10000)
            );
        });
    }
}
