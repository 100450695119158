import { selectAcknowledgedCompliance } from '@app/store/selectors/auth.selectors';
import { inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { State } from '@app/store/states';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { mpiConfig } from '../config/app-config.constants';
import { map, take } from 'rxjs/operators';


export const ComplianceGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store<State> = inject(Store),
    router: Router = inject(Router)
): Observable<boolean> => {
    return store.pipe(
        select(selectAcknowledgedCompliance),
        take(1),
        map(value => {
            if (!value) {
                router.navigate([mpiConfig.auth.forbiddenRedirect], { queryParams: route.queryParams });
            }
            return value;
        })
    );
}