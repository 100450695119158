import { recordsKey } from './../reducers/records.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RecordsState } from '../reducers';
import { IListProfileBoosterAttributesByMpiId } from '@app/containers/mci/search/details/search-details.config';

const getRecordsState = createFeatureSelector<RecordsState>(recordsKey);

export const selectSearchRecords = createSelector(getRecordsState, state => state.search);

export const selectTotalSearchRecords = createSelector(getRecordsState, state => (state.search || []).length);

export const selectSearchRecordsSelected = createSelector(getRecordsState, state =>
    state.search !== null
        ? state.search.filter(item => state.idsSelectedSearchResult.includes(item.mpiRecordId))
        : []
);

export const selectIdsSelectedSearchRecords = createSelector(getRecordsState, state => state.idsSelectedSearchResult);

export const selectIdentityDetailRecord = createSelector(getRecordsState, state =>
    (state.currentRecords && state.currentRecords.length) ? state.currentRecords[0] : null
);

export const selectCurrentRecords = createSelector(getRecordsState, state => state.currentRecords);

export const selectHouseholdInsightRecords = createSelector(getRecordsState, state => state.householdInsightsRecords);

export const selectBestDetails = createSelector(getRecordsState, state => state.bestDetails);

export const selectMortalityInsights = createSelector(getRecordsState, state => state.mortalityInsights);

export const selectProfileBooster = createSelector(getRecordsState, state => state.profileBooster);

export const selectSplitData = createSelector(getRecordsState, state => state.splitData);

export const selectMergeData = createSelector(getRecordsState, state => state.mergeIdentities);

export const selectProfileBoosterByMpiIds = (props: { mpiIds: string[] }) => createSelector(getRecordsState, state => {
    const result: IListProfileBoosterAttributesByMpiId = {};
    Object.keys(state.profileBooster).forEach(mpiId => {
        if (props.mpiIds.includes(mpiId)) {
            result[mpiId] = state.profileBooster[mpiId];
        }
    });
    return result;
});

export const selectProfileBoosterByMpiId = (props: { mpiId: string }) => createSelector(getRecordsState, state => {
    const id = Object.keys(state.profileBooster).find(mpiId => props.mpiId === mpiId);
    return id ? state.profileBooster[id] : null;
});

export const selectHistoricalRecords = createSelector(getRecordsState, state => state.historicalRecords);
