export enum MpiStorageKey {
    lastSearchRequest = 'mpi-last-search-request',
    idSelected = 'mpi-record-id-selected',
    mergeElements = 'mpi-merge-records',
    splitElementId = 'mpi-split-id',
    actionFrom = 'mpi-action-from'
}

export enum MpiActionFromPage {
    searchResult = 'sr',
    worklist = 'wl'
}

export enum MpiWorklistSection {
    identities = 'I',
    transactions = 'T'
}

export enum MpiRecordStatus {
    underEvaluation = 'under evaluation',
    mergePendeing = 'merge pending',
    splitPendeing = 'split pending',
    addPendeing = 'add pending',
}

export type GenderType = 'M' | 'F' | 'N/A';

export interface ISearchResponse {
    status: number;
    body: {
        data: IMpiRecord[];
        resquest: any;
    };
    data: IMpiRecord[] | any;
}

export interface IMpiRecord {
    active?: boolean;
    rid?: number;
    sourceRid: number;
    emailAddress?: string;
    matchingFields: string[];
    dlNumber: string;
    dlState: string;
    accountId: string;
    consortiumId?: number;
    customerSourceLevel1?: string;
    customerSourceLevel2?: string;
    customerSourceLevel3?: string;
    address?: IAddress;
    addresses?: IAddress[];
    weight: number;
    customerName: string;
    dateAdded: string;
    dateChanged: string;
    dateFirstSeen: string;
    dateLastSeen: string;
    dateOfBirth: string;
    dateVendorFirstReported: string;
    dateVendorLastReported: string;
    endDate: string;
    firstName: string;
    namePrefix: string;
    gcId: number;
    memberSequenceId?: string;
    responseType?: string;
    groupId: string;
    guardianDob: string;
    guardianFirstName: string;
    guardianLastName: string;
    guardianLexId: number;
    guardianLexIdScore: string;
    guardianMiddleName: string;
    guardianSsn: string;
    lastName: string;
    lexId: number;
    lexIdScore: number;
    mpiId: string;
    householdId: string;
    mpiRecordId: number;
    mpiIdChanged?: boolean;
    memberId: string;
    middleName: string;
    nameFullInput: string;
    nameSuffix: string;
    nameTitle: string;
    noMatchId: number;
    phones: IPhone[];
    relationshipCode: string;
    source: string;
    ssn: string;
    startDate: string;
    status: string;
    subscriberId: string;
    userAdded: string;
    userChanged: string;
    worklist?: IWorklistUser[];
    immutableRecord?: boolean;
    recordType?: string;
    gender?: GenderType;
    birthSex?: GenderType;
    usCoreRace?: ICoreRace;
    usCoreEthnicity?: ICoreEthnicity;
    communicationDtoList?: ICommunicationDto[];
}

export interface ICommunicationDto {
    id?: string;
    preferred?: boolean;
    languageDto: ILanguage;
}

export interface ILanguage {
    system?: string;
    code?: string;
    display: string;
    text?: string;
}

export interface ICoreRace {
    ombCategoryCode?: ICodeData[];
    detailedRaceCode?: ICodeData[];
    text: string;
}

export interface ICoreEthnicity {
    ombCategoryCode?: ICodeData;
    detailedEthnicityCode?: ICodeData[];
    text: string;
}

export interface ICodeData {
    code: string;
    display: string;
    definition: string;
    system: string;
}

export interface IPhone {
    phoneNumber: string;
    phoneType?: string;
}

export interface IWorklistUser {
    login_id: string;
    login_name: string;
    mci_record_id: number;
}

export interface IAddress {
    addressType?: string;
    addressLine1?: string;
    addressLine2?: string;
    rawCityName?: string;
    rawState?: string;
    rawZipCode?: string;
    cityName?: string;
    state?: string;
    zipCode?: string;
    zip4?: string;
}

export interface ISearchParam {
    mpiId?: string;
    mpiHouseholdId?: string;
    customerSourceLevel1?: string;
    customerSourceLevel2?: string;
    customerSourceLevel3?: string;
    name?: ISearchFullName;
    address?: ISearchAddress;
    dob?: ISearchDOB;
    phone?: string;
    emailAddress?: string;
    ssn?: string;
    gender?: string;
    driverLicense?: ISearchDriverLicense;
}

export interface ISearchFullName {
    first: string;
    last: string;
    middle: string;
}

export interface ISearchAddress {
    streetAddress1: string;
    city: string;
    state: string;
    zip5: string;
}

export interface ISearchDOB {
    month: number;
    day: number;
    year: number;
}

export interface ISearchDriverLicense {
    driverLicenseNumber: number;
    driverLicenseState: string;
}
