import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { AuthenticationService } from '@app/core/services/backend/authentication.service';
import { LogPriority } from '@app/core/services/log/log-priority.enum';
import { of } from 'rxjs';
import { AppLog } from '@app/core/services/log/app-log.service';
import { Router } from '@angular/router';
import { ACL_PERMISSIONS_VALUE } from '@app/core/services/acl/config/acl-permissions';
import { BackendService } from '@app/core/services';
import { mpiConfig } from '@app/core/config/app-config.constants';
import * as fromActions from '@app/store/actions';

@Injectable()
export class AuthEffects {

    constructor(
        private actions$: Actions,
        private authService: AuthenticationService,
        private backendService: BackendService,
        private logger: AppLog,
        private router: Router
    ) {
    }

    loginByToken$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.loginByToken),
        exhaustMap(({ token }) => this.authService.ssoLogin(token).pipe(
            map(() => {
                this.router.navigate([mpiConfig.auth.loginRedirect]);
                return fromActions.loadUserIdentity();
            }),
            catchError((error) => {
                this.logger.error('Error login by SSO token', { logPriority: LogPriority.HIGH }, error);
                return of(fromActions.logout());
            })
        ))
    ));

    loadUserIdentity$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.loadUserIdentity),
        exhaustMap(() => this.backendService.doGet(mpiConfig.api.identity).pipe(
            exhaustMap(response => {
                return [
                    fromActions.loadUserPermissions({ identity: response.data }),
                    fromActions.setUserIdentity({ identity: response.data })
                ];
            }),
            catchError((error) => {
                this.logger.error('Error retrieving identity', { logPriority: LogPriority.HIGH }, error);
                return of(fromActions.logout());
            })
        ))
    ));

    loadUserPermissions$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.loadUserPermissions),
        map(({ identity }) => {
            if (!identity) {
                return fromActions.setUserPermissions({ permissions: [] });
            }
            let permissions = [];
            identity.user.nvps.forEach(nvp => {
                if (+nvp.value === 1) {
                    permissions.push(nvp.name);
                }
            });
            permissions = [
                ...permissions,
                ...identity.user.permissions.map(permission => permission.code),
                ACL_PERMISSIONS_VALUE.NONE
            ];
            return fromActions.setUserPermissions({ permissions });
        })
    ));

    logout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromActions.logout),
            map(() => this.authService.logout())
        );
    },
    { dispatch: false });
}
