import { storeReset } from 'ngrx-store-reset';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as fromReducers from '@app/store/reducers';

export interface State {
    [fromReducers.authKey]: fromReducers.AuthState;
    [fromReducers.recordsKey]: fromReducers.RecordsState;
    [fromReducers.uiKey]: fromReducers.UIState;
    [fromReducers.worklistKey]: fromReducers.WorklistState;
}

export const reducers: ActionReducerMap<State> = {
    [fromReducers.authKey]: fromReducers.authReducer,
    [fromReducers.recordsKey]: fromReducers.recordsReducer,
    [fromReducers.uiKey]: fromReducers.uiReducer,
    [fromReducers.worklistKey]: fromReducers.worklistReducer
};

export function storeResetMetaReducer(reducer: ActionReducer<State>): ActionReducer<State> {
    return storeReset({ action: '[AUTH] Logout Success' })(reducer);
}

export const metaReducers: MetaReducer<State>[] = [storeResetMetaReducer];
