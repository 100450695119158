import { logout } from '@app/store/actions/auth.actions';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppLog } from '@services/log/app-log.service';
import { HttpClient } from '@angular/common/http';
import { _getRequestOptions, _getRequestUrl } from '@services/backend/backend-utility-methods';
import { LogPriority } from '@services/log/log-priority.enum';
import { Store } from '@ngrx/store';
import { State } from '@app/store/states';
import { mpiConfig } from '@app/core/config/app-config.constants';

@Injectable({
    providedIn: 'root'
})
export class BackendService {

    protected navigateOn401 = true;

    constructor(
        protected http: HttpClient,
        protected logger: AppLog,
        protected store: Store<State>
    ) { }

    doGet(url: string, headers?: any) {
        headers = headers ?? _getRequestOptions();
        return this.http.get(_getRequestUrl(url), headers)
            .pipe(
                map((res: any) => this.handleSuccessfulResponse(res)),
                catchError((error: any) => this.errorHandler(error))
            );
    }

    doPatch(url: string, payload: any, headers?: any) {
        headers = headers ?? _getRequestOptions();
        return this.http.patch(_getRequestUrl(url), payload, headers)
            .pipe(
                map((res: any) => this.handleSuccessfulResponse(res)),
                catchError((error: any) => this.errorHandler(error))
            );
    }

    doPut(url: string, payload: string, headers?: any) {
        headers = headers ?? _getRequestOptions();
        return this.http.put(_getRequestUrl(url), payload, headers)
            .pipe(
                map((res: any) => this.handleSuccessfulResponse(res)),
                catchError((error: any) => this.errorHandler(error))
            )
    }

    doPost(url: string, payload: string, headers?: any) {
        headers = headers ?? _getRequestOptions();
        return this.http.post(_getRequestUrl(url), payload, headers)
            .pipe(
                map((res: any) => this.handleSuccessfulResponse(res)),
                catchError((error: any) => this.errorHandler(error))
            );
    }

    doDelete(url: string, headers?: any) {
        headers = headers ?? _getRequestOptions();
        return this.http.delete(_getRequestUrl(url), headers)
            .pipe(
                map((res: any) => this.handleSuccessfulResponse(res)),
                catchError((error: any) => this.errorHandler(error))
            );
    }

    protected handleSuccessfulResponse(response: any) {
        if (response) {
            this.logger.log('In backend ', { logPriority: LogPriority.DO_NOT_STORE });
            const status = response.status;
            const body = response.body;
            const data = (response && response.body && response.body.data) ? response.body.data : null;
            return { status, body, data: data };
        } else {
            return response;
        }
    }

    protected errorHandler(error) {
        if (error.status === 401 && this.navigateOn401 === true) {
            this.store.dispatch(logout());
        }
        if (typeof error === 'string') {
            return throwError(() => new Error(error));
        }
        this.logger.error(
            'Error in API call',
            { logPriority: LogPriority.MEDIUM, includeFrontendSessionData: true },
            error
        );
        return throwError(() => error);
    }
}
