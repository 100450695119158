import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, take, takeUntil, exhaustMap } from 'rxjs/operators';
import { AppLog } from '@app/core/services/log/app-log.service';
import { LogPriority } from '@app/core/services/log/log-priority.enum';
import { of, combineLatest } from 'rxjs';
import { ResponseMessage } from '@app/core/config/response-message.config';
import { UtilsService } from '@services/backend/mci/utils.service';
import { SearchService } from '@services/backend/mci/search.service';
import { IMpiRecord, MpiRecordStatus } from '@services/backend/mci/search.config';
import { IListProfileBoosterAttributesByMpiId, IIdentityDetail, IHistoryTransaction } from '@app/containers/mci/search/details/search-details.config';
import { select, Store } from '@ngrx/store';
import { State } from '../states';
import * as fromSelectors from '@app/store/selectors';
import * as fromActions from '@app/store/actions';

@Injectable()
export class RecordsEffect {

    constructor(
        private actions$: Actions,
        private logger: AppLog,
        private utilsService: UtilsService,
        private searchService: SearchService,
        private store: Store<State>
    ) {
    }

    updateSearchRecordsStatus$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.RecordsActions.updateSearchRecordsStatus),
        exhaustMap(() => {
            const searchRecords$ = this.store.pipe(select(fromSelectors.selectSearchRecords), take(1));
            const identityIds$ = this.store.pipe(select(fromSelectors.selectIdentityMpiRecordIds), take(1));
            const transactionMpiIds$ = this.store.pipe(select(fromSelectors.selectTransactionMpiIds), take(1));
            return combineLatest([searchRecords$, identityIds$, transactionMpiIds$]).pipe(
                map(([searchRecords, identityIds, transactionMpiIds]) => {
                    if ((searchRecords || []).length) {
                        const recordsUpdated = searchRecords.map(record => {
                            if (UtilsService.hasPendingStatus(record)) {
                                return record;
                            }
                            const status = (
                                (record.worklist || []).length ||
                                identityIds.includes(record.mpiRecordId) ||
                                transactionMpiIds.includes(record.mpiId)
                            ) ? MpiRecordStatus.underEvaluation : '';
                            return { ...record, status };
                        });
                        return fromActions.RecordsActions.setSearchRecords({ records: recordsUpdated });
                    }
                    return fromActions.RecordsActions.clearSearchResult();
                })
            );
        })
    ));

    search$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.RecordsActions.search),
        exhaustMap(({ params }) => this.searchService.search(params).pipe(
            takeUntil(this.actions$.pipe(ofType(fromActions.cancelRequests))),
            map((records: IMpiRecord[]) => fromActions.RecordsActions.setSearchRecords({ records })),
            catchError(error => {
                this.logger.error('Error performing a search', { logPriority: LogPriority.HIGH }, error);
                return of(
                    fromActions.RecordsActions.setSearchRecords({ records: [] }),
                    fromActions.showErrorMessage({ message: ResponseMessage.ERROR_MESSAGES.SEARCH_ERROR })
                );
            })
        ))
    ));

    doLastSearch$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.RecordsActions.doLastSearch),
        exhaustMap(() => {
            const params = this.utilsService.existRequestInStorage();
            if (params) {
                return this.searchService.search().pipe(
                    takeUntil(this.actions$.pipe(ofType(fromActions.cancelRequests))),
                    map((records: IMpiRecord[]) => fromActions.RecordsActions.setSearchRecords({ records })),
                    catchError(error => {
                        this.logger.error('Error performing last search', { logPriority: LogPriority.HIGH }, error);
                        return of(fromActions.RecordsActions.setSearchRecords({ records: [] }));
                    })
                );
            } else {
                return of(fromActions.RecordsActions.setSearchRecords({ records: [] }));
            }
        })
    ));

    loadDataHistoricalRecords$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.HistoryRecordsActions.loadData),
        exhaustMap(({ mpiId }) => this.searchService.getHistoryRecords(mpiId).pipe(
            takeUntil(this.actions$.pipe(ofType(fromActions.cancelRequests))),
            map((records: IHistoryTransaction[]) => fromActions.HistoryRecordsActions.setData({ records })),
            catchError(error => {
                this.logger.error('Error loading historical records', { logPriority: LogPriority.HIGH }, error);
                return of(fromActions.HistoryRecordsActions.setData({ records: [] }));
            })
        ))
    ));

    compareRecords$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.RecordsActions.compareRecords),
        map(({ firstRecord, secondRecord }) => {
            const records = this.utilsService.setPrincipalAddress([firstRecord, secondRecord]);
            this.searchService.openCompareDialog(records);
        })
    ), { dispatch: false });

    comparePublicRecord$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.RecordsActions.comparePublicRecord),
        map(({ record, publicRecord }) => {
            const records = [...this.utilsService.setPrincipalAddress([record]), publicRecord];
            this.searchService.openCompareDialog(records as IMpiRecord[], true);
        })
    ), { dispatch: false });

    loadIdentityDetails$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.RecordsActions.loadIdentityDetails),
        exhaustMap(({ mpiId, loadHouseholdInfo }) => this.searchService.getIdentityDetails(mpiId).pipe(
            takeUntil(this.actions$.pipe(ofType(fromActions.cancelRequests))),
            exhaustMap((response: IIdentityDetail) => {
                const householdInsightsAction = !!(loadHouseholdInfo === true && response.mpiRecords.length && response.mpiRecords[0].householdId)
                    ? fromActions.HouseholdInsightsActions.loadData({ householdId: response.mpiRecords[0].householdId })
                    : fromActions.HouseholdInsightsActions.setData({ records: [] });
                const profileBooster = { [mpiId]: (response.profileBoosterAttributesAvailable === true) ? response.profileBooster?.attributes : null }
                return [
                    fromActions.RecordsActions.setTransactions({ records: response.mpiRecords }),
                    fromActions.setBestDetails({ data: (response.bestDetailsAvailable === true) ? response.bestDetails : null }),
                    fromActions.setMortalityInsights({ data: (response.mortalityInsightsAvailable === true) ? response.mortalityInsights : null }),
                    fromActions.ProfileBoosterActions.setData({ data: profileBooster }),
                    householdInsightsAction
                ];
            }),
            catchError(error => {
                this.logger.error('Error loading identity details', { logPriority: LogPriority.HIGH }, error);
                return [
                    fromActions.RecordsActions.clearIdentityDetails(),
                    fromActions.RecordsActions.setTransactions({ records: [] })
                ];
            })
        ))
    ));

    loadInBatch$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.ProfileBoosterActions.loadInBatch),
        exhaustMap(({ mpiIds }) => this.searchService.getProfileBoosterInBatch(mpiIds).pipe(
            takeUntil(this.actions$.pipe(ofType(fromActions.cancelRequests))),
            map((profileBooster: IListProfileBoosterAttributesByMpiId) => fromActions.ProfileBoosterActions.setData({ data: profileBooster })),
            catchError(error => {
                this.logger.error('Error loading identity details in batch', { logPriority: LogPriority.HIGH }, error);
                const result = {};
                mpiIds.forEach(mpiId => result[mpiId] = null);
                return of(fromActions.ProfileBoosterActions.setData({ data: result }));
            })
        ))
    ));

    loadByMpiId$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.ProfileBoosterActions.loadByMpiId),
        exhaustMap(({ mpiId }) => this.searchService.getIdentityDetails(mpiId).pipe(
            takeUntil(this.actions$.pipe(ofType(fromActions.cancelRequests))),
            map((response: IIdentityDetail) => {
                const profileBooster = { [mpiId]: (response.profileBoosterAttributesAvailable === true) ? response.profileBooster?.attributes : null }
                return  fromActions.ProfileBoosterActions.setData({ data: profileBooster });
            }),
            catchError(error => {
                this.logger.error('Error loading profile booster data', { logPriority: LogPriority.HIGH }, error);
                return of(fromActions.ProfileBoosterActions.setData({ data: { [mpiId]: null } }));
            })
        ))
    ));

    LoadDataHouseholdInsights$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.HouseholdInsightsActions.loadData),
        exhaustMap(({ householdId }) => this.searchService.getRecordsByHouseholdId(householdId).pipe(
            map((records: IMpiRecord[]) => fromActions.HouseholdInsightsActions.setData({ records })),
            catchError(error => {
                this.logger.error('Error loading household insights records', { logPriority: LogPriority.HIGH }, error);
                return of(fromActions.HouseholdInsightsActions.setData({ records: [] }));
            })
        ))
    ));
}
