import { UserInfo } from '@core/data-models/user/user-info.model';
import { Identity } from '@core/data-models/user/identity.model';
import { Nvp, NvpType } from '@core/data-models/user/nvp.model';
import { AppPermissions } from '@core/services/acl/config/acl-permissions-constants';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState, authKey } from '../reducers';

const getAuthState = createFeatureSelector<AuthState>(authKey);

export const selectAuthIdentity = createSelector(getAuthState, state => state.identity as Identity);

export const selectIsAuth = createSelector(getAuthState, state => state.authenticated);

// User
export const selectAuthUserInfo = createSelector(getAuthState, state =>
    !!state.identity ? state.identity.user.userInfo as UserInfo : null);

export const selectAuthUserRoles = createSelector(getAuthState, state =>
    !!state.identity ? state.identity.user.roles : null);

export const selectAuthUserFullName = createSelector(getAuthState, state =>
    !!state.identity
        ? `${state.identity.user.userInfo.firstName} ${state.identity.user.userInfo.lastName}`
        : null
);

export const selectAuthUserName = createSelector(
    getAuthState,
    state => !!state.identity ? state.identity.user.userInfo.userName : null);

export const selectAuthUserCompliances = createSelector(getAuthState, state =>
    !!state.identity ? state.identity.user.compliances.mpi : null);

// NVP
export const selectAuthUserNvps = createSelector(getAuthState, state =>
    !!state.identity ? state.identity.user.nvps : null);

export const selectNvpByName = (props: { name: string, type?: NvpType }) =>
    createSelector(getAuthState, state => {
        if (!state.identity) {
            return null;
        }
        const type = props.type ? props.type : NvpType.user;
        const result = state.identity.user.nvps
            .filter(nvp => nvp.type === type)
            .find(nvp => nvp.name === props.name);
        if (!result) {
            return null;
        }
        const nvpModel = new Nvp();
        return nvpModel.fromJson(result);
});

export const selectNvpValue = (props: { name: string }) => createSelector(getAuthState, state => {
    if (!state.identity) {
        return null;
    }
    const result = state.identity.user.nvps.find(nvp => nvp.name === props.name);
    if (!result) {
        return null;
    }
    return result.value;
});

// Company
export const selectAuthCompanyId = selectNvpValue({ name: 'gc_id' });

// Permissions
export const selectAuthUserPermissions = createSelector(getAuthState, state => state.userPermissions);

export const selectAcknowledgedCompliance = createSelector(
    getAuthState,
    state => {
        if (!state.identity) {
            return false;
        }
        const compliances = state.identity.user.compliances.mpi;
        return +compliances?.dmf > 0 && +compliances?.dppa > 0 && +compliances?.glba > 0;
    }
);

export const selectUserHasPermissionByCode = (props: { code: string }) => createSelector(getAuthState, state => {
    if (!state.identity || !state.identity.user.permissions) {
        return false;
    }
    return state.identity.user.permissions.some(permission => permission.code === props.code);
});

export const selectUserHasPermission = (props: { permissions: string[] }) => createSelector(getAuthState, state => {
    if (!state.userPermissions.length || !(props.permissions || []).length) {
        return false;
    }
    return props.permissions.some(permission => state.userPermissions.includes(permission));
});

export const selectUserHasPermissionToHousehold = createSelector(getAuthState, state => {
    return state.userPermissions.includes(AppPermissions.mci.household);
});

export const selectUserHasPermissionToBestIdentity = createSelector(getAuthState, state => {
    return state.userPermissions.includes(AppPermissions.mci.bestIdentity);
});

export const selectUserHasPermissionToPB = createSelector(getAuthState, state => {
    return !!(state.userPermissions.includes(AppPermissions.mci.profileBoostertHealth) || state.userPermissions.includes(AppPermissions.mci.profileBoostertServices));
});

export const selectUserHasPermissionToPBHealth = createSelector(getAuthState, state => {
    return state.userPermissions.includes(AppPermissions.mci.profileBoostertHealth);
});

export const selectUserHasPermissionToMortalityInsights = createSelector(getAuthState, state => {
    return state.userPermissions.includes(AppPermissions.mci.mortalityInsights);
});

export const selectUserHasPermissionToHouseholdInsights = createSelector(getAuthState, state => {
    const permissions = state.userPermissions;
    const mpiPermissions = AppPermissions.mci;
    const householdAccess = permissions.includes(mpiPermissions.household) && permissions.includes(mpiPermissions.householdInsights);
    const profileBoosterAccess = !!(permissions.includes(mpiPermissions.profileBoostertHealth) || permissions.includes(mpiPermissions.profileBoostertServices));
    return householdAccess && profileBoosterAccess;
});
