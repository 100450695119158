import { UserIdentityInterface } from '@app/core/data-models/user/identity.model';
import { createAction, props } from '@ngrx/store';


export const loadUserIdentity = createAction('[AUTH] Load Identity');

export const loginByToken = createAction('[AUTH] Login By Token', props<{ token: string }>());

export const setUserIdentity = createAction('[AUTH] Set Identity', props<{ identity: UserIdentityInterface }>());

export const loadUserPermissions = createAction('[AUTH] Load User Permissions',
    props<{ identity: UserIdentityInterface }>());

export const setUserPermissions = createAction('[AUTH] Set User Permissions',
    props<{ permissions: string[] }>());

export const logout = createAction('[AUTH] Logout');

export const logoutSuccess = createAction('[AUTH] Logout Success');
