import * as fromReducers from './../reducers/worklist.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const getWorklistState = createFeatureSelector<fromReducers.WorklistState>(fromReducers.worklistKey);

const identitiesState = (state: fromReducers.WorklistState) => state.identities;
const getWorklistIdentitiesState = createSelector(getWorklistState, identitiesState);

const transactionsState = (state: fromReducers.WorklistState) => state.transactions;
const getWorklistTransactionsState = createSelector(getWorklistState, transactionsState);

const {
    selectTotal: totalIdentities,
    selectAll: allIdentities
} = fromReducers.identityAdapter.getSelectors();

const {
    selectTotal: totalTransactions,
    selectAll: allTransactions
} = fromReducers.transactionAdapter.getSelectors();

// Identities
export const selectTotalIdentities = createSelector(getWorklistIdentitiesState, totalIdentities);

export const selectAllIdentities = createSelector(getWorklistIdentitiesState, allIdentities);

export const selectIdentityMpiRecordIds = createSelector(getWorklistIdentitiesState, state => state.ids as number[]);

export const selectIdentityMpiIds = createSelector(getWorklistIdentitiesState, state =>
    Object.values(state.entities).map(transaction => transaction.mpiId as string)
);

export const selectIdentitiesLoaded = createSelector(getWorklistState, state => state.identities.identitiesLoaded);

export const selectIdentitiesSelected = createSelector(getWorklistIdentitiesState, state =>
    Object.values(state.entities).filter(identity => state.idsSelected.includes(identity.mpiRecordId))
);

export const selectIdentityIdsSelected = createSelector(getWorklistState, state => state.identities.idsSelected);

export const selectIdentitiesByMpiIds = (props: { mpiIds: string[] }) => createSelector(getWorklistIdentitiesState, state =>
    Object.values(state.entities).filter(identity => props.mpiIds.includes(identity.mpiId))
);

// Transactions
export const selectTotalTransactions = createSelector(getWorklistTransactionsState, totalTransactions);

export const selectAllTransactions = createSelector(getWorklistTransactionsState, allTransactions);

export const selectTransactionMpiRecordIds = createSelector(getWorklistTransactionsState, state => state.ids as number[]);

export const selectTransactionMpiIds = createSelector(getWorklistTransactionsState, state =>
    Object.values(state.entities).map(transaction => transaction.mpiId as string)
);

export const selectTransactionsLoaded = createSelector(getWorklistState, state => state.transactions.transactionsLoaded);

export const selectTransactionsSelected = createSelector(getWorklistState, state =>
    Object.values(state.transactions.entities).filter(transaction => state.transactions.idsSelected.includes(transaction.mpiRecordId))
);

export const selectTransactionIdsSelected = createSelector(getWorklistState, state => state.transactions.idsSelected);

export const selectTransactionsByMpiIds = (props: { mpiIds: string[] }) => createSelector(getWorklistTransactionsState, state =>
    Object.values(state.entities).filter(transaction => props.mpiIds.includes(transaction.mpiId))
);

export const selectTransactionsByMpiRecordIds = (props: { mpiRecordIds: number[] }) => createSelector(getWorklistTransactionsState, state =>
    Object.values(state.entities).filter(transaction => props.mpiRecordIds.includes(transaction.mpiRecordId))
);
