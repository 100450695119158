import { UserIdentityInterface } from '@app/core/data-models/user/identity.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromAction from '../actions/auth.actions';


export const authKey = 'auth';

export interface AuthState {
    identity: UserIdentityInterface;
    userPermissions: string[];
    authenticated: boolean;
}

export const initialAuthState: AuthState = {
    identity: undefined,
    userPermissions: [],
    authenticated: undefined
};

export const authReducers = createReducer(
    initialAuthState,
    on(fromAction.setUserIdentity, (state, { identity }) => ({ ...state, authenticated: true, identity })),
    on(fromAction.setUserPermissions, (state, { permissions }) => ({ ...state, userPermissions: permissions })),
    on(fromAction.logoutSuccess, (state) => ({ ...state, authenticated: false }))
);

export function authReducer(state: AuthState | undefined, action: Action): AuthState {
    return authReducers(state, action);
}
