export enum Icons {
    Home = 'far fa-home',
    Identity = 'far fa-user-tie',
    Address = 'far fa-map-marked',
    Ip = 'far fa-globe-americas',
    Ssn = 'far fa-address-card',
    Email = 'far fa-at',
    Phone = 'far fa-phone',
    BankAccount = 'far fa-sack-dollar',
    DriverLicense = 'far fa-car',
    KnownRisk = 'far fa-exclamation-circle',
    SafeList = 'far fa-check-circle',
    Worklist = 'far fa-clipboard-list',
    Search = 'far fa-search',
    SearchFile = 'far fa-file-search',
    PieChart = 'fas fa-chart-pie',
    Building = 'far fa-landmark',
    Edit = 'far fa-edit',
    FileEdit = 'far fa-file-edit',
    Run = 'far fa-running',
    Delete = 'far fa-trash-alt',
    Lightbulb = 'far fa-lightbulb-on',
    DashboardAnalytics = 'far fa-analytics',
    Element = 'far fa-share-alt',
    BackgroundScatter = 'far fa-chart-scatter',
    Users = 'far fa-users',
    Compare = 'far fa-clone',
    HandShake = 'far fa-handshake',
    Split = 'far fa-arrows-h',
    Create = 'far fa-file-plus',
    InfoCircle = 'far fa-info-circle',
    PlusCircle = 'far fa-plus-circle',
    MinusCircle = 'far fa-minus-circle',
    ArrowCircleRight = 'far fa-arrow-circle-right',
    ExchangeAlt = 'far fa-exchange-alt',
    Reports = 'far fa-file-chart-line'
}
