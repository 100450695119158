import { Component, inject, OnInit, signal } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { selectUILoading } from './store/selectors/ui.selectors';
import { Store } from '@ngrx/store';
import { RouterOutlet } from '@angular/router';
import { RoutingState } from '@core/services/routing-state';
import { AuthenticationService } from '@services/backend/authentication.service';
import { loadUserIdentity, logout } from './store/actions/auth.actions';
import { AccessibilityService } from '@services/analytics-ui/allycat/accessibility.service';
import { ApplicationInsightsLogsService } from '@services/log/application-insights/application-insights-logs.service';
import { LetDirective } from '@ngrx/component';
import { LoadingSpinnerComponent } from '@shared/ui/misc/loading-spinner/loading-spinner.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [CommonModule, RouterOutlet, LetDirective, LoadingSpinnerComponent],
    providers: [AccessibilityService],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

    protected loading = signal<boolean>(false);
    private readonly store: Store = inject(Store);

    constructor(
        private location: Location,
        private routingState: RoutingState,
        private accessibilityService: AccessibilityService,
        private appInsightsSevice: ApplicationInsightsLogsService
    ) {
        this.store.select(selectUILoading)
            .pipe(takeUntilDestroyed())
            .subscribe((loading: boolean) => this.loading.set(loading));
    }

    ngOnInit() {
        this.routingState.setUrlHistoryInLocalStore();
        this.accessibilityService.start();
        this.loadData();
        this.appInsightsSevice.initialize();
    }

    private loadData(): void {
        const path = this.location.path();
        if (!!sessionStorage.getItem(AuthenticationService.authTokenStoreKey)) {
            this.store.dispatch(loadUserIdentity());
        } else if (!path.includes('/sso/')) {
            this.store.dispatch(logout());
        }
    }

}
