export class ResponseMessage {
    public static ERROR_MESSAGES = {
        MISSING_NECESSARY_FIELDS: 'Missing required input: Enter a name, address or SSN to search.',
        MISSING_ALL_REQUIRE_FIELDS: 'Missing required input: Enter a name, address or SSN in Application Information and enter Program Type and Date Range.',
        REQUIRE_FIELDS: 'Missing required input: Enter Program Type and Data Range.',
        SEARCH_CRITERIA_ERROR: 'Please add additional search criteria.',
        SEARCH_ERROR_HELP_INFO: 'Please add additional search criteria. See help info for valid search combinations.',
        COMPLETE_REQUIRED_FIELDS: 'Complete the fields marked as required AND enter the combination of the following: Program State + Case Identifier or Client Identifier  OR Program + the name, address, DOB, or SSN within the Applicant Information section.',
        INSUFFICIENT_INPUT: 'Insufficient Input: The combination of one the following is also required: Program State + Case Identifier or Client Identifier OR Name, address, DOB, or SSN within the Applicant Information section.',
        SEARCH_ERROR: 'An error has occurred while performing the search.',
        GENERATE_REPORTS_ERROR: 'An error occurred while trying to generate your reports.',
        NO_RESULTS_ERROR: 'Your current search produced no results. Please try your search again.',
        UNEXPECTED_ERROR: 'An unexpected error has occurred.',
        TOO_MANY_RESULTS: 'Your current search is too broad.  Please refine your search and try again.',
    };
    public static ERROR_RESPONSE_CODES = {
        ROXIE_ERROR: 'ROXIE_ERROR',
        ROXIE_ERROR_TOO_MANY_RESULTS: 'ROXIE_ERROR_TOO_MANY_RESULTS'
    };
}
