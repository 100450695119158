export interface IAutoRenewSessionConfig {
    checkIdleEvery: number;     // in milliseconds
    checkRenewEvery: number;    // in milliseconds
    userAnswerTime: number;     // in milliseconds

    idleTime: number;           // in minutes

    expirationTime: number;     // in seconds
    renewBefore: number;        // in seconds

    modalTitle?: string;
    modalMessage?: string;
    modalIcon?: string;
    modalAceptLabel?: string;
    modalCancelLabel?: string;
}

export const RENEW_SESSION_CONFIG: IAutoRenewSessionConfig = {
    checkIdleEvery: 30000,      // 30 seconds
    userAnswerTime: 300000,     // 60 seconds = 1 minute
    checkRenewEvery: 10000,     // 10 seconds

    idleTime: 55,               // 55 minutes

    expirationTime: 3600,       // 60 minutes
    renewBefore: 300,           // 5 minutes

    modalTitle: 'Session expiring soon',
    modalMessage: 'Your session is about to expire. Please click continue to extend your session.',
    modalAceptLabel: 'Continue',
    modalCancelLabel: 'Cancel',
};
