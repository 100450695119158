export enum LogType {
    DEBUG = 'debug',
    INFO = 'info',
    NOTICE = 'info',
    WARNING = 'warn',
    ERROR = 'error',
    CRITICAL = 'error',
    ALERT = 'error',
    EMERGENCY = 'error',
}
