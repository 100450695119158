/**
 * Product module types
 */
export enum ProductRouteModules {
    None,
    Identity = 'Identity Risk Navigator',
    Medicaid = 'Medicaid',
    Unemployment = 'Unemployment',
    Snap = 'SNAP/CardSwipe',
    PPA = 'Program Participation Analyzer',
    MCI = 'Master Person Index'
}

export enum ModuleNameKey {
    IdentityRisk = 'idr',
    PPA = 'ppa',
    MCI = 'mci'
}

export const ModuleLabel = {
    [ModuleNameKey.IdentityRisk]: ProductRouteModules.Identity,
    [ModuleNameKey.PPA]: ProductRouteModules.PPA,
    [ModuleNameKey.MCI]: ProductRouteModules.MCI,
};
