import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ReportType } from '@app/containers/mci/reports/reports.config';
import { ApiResponseReportsTypes } from '@app/core/services/backend/interfaces/reports-response.interface';


export const ReportsActions = createActionGroup({
    source: 'REPORTS',
    events: {
        'Load Reports': props<{ reportType: ReportType, startDate: string, endDate: string }>(),
        'Set Reports': props<{ reports: ApiResponseReportsTypes }>(),
        'Clear Reports': emptyProps()
    },
});