import { InjectionToken } from '@angular/core';
import { AclPermissionsInterface } from '@app/core/services/acl/config/acl-permissions.interface';

export let ACL_PERMISSIONS = new InjectionToken<AclPermissionsInterface>('acl.values');
export const MCI_PREFIX = '/mci';

export const ACL_PERMISSIONS_VALUE: AclPermissionsInterface = {
    NONE: 'NONE',

    SETTINGS: '/settings',
    VIEW_USER: '/settings/(.)+/users',
    USER_PREFERENCES: '/settings/(.)+/user-preferences',

    // MCI
    MCI_SEARCH: `${MCI_PREFIX}/search`,
    MCI_WORKLIST: `${MCI_PREFIX}/worklist`,
    MCI_PENDING_TRANSACTIONS: `${MCI_PREFIX}/pending-transactions`,
    MCI_REPORTS: `${MCI_PREFIX}/reports`,
    MCI_MERGE: `${MCI_PREFIX}/merge`,
    MCI_SPLIT: `${MCI_PREFIX}/split`,
    MCI_SEARCH_RESULTS: `${MCI_PREFIX}/search/results`,
    MCI_SEARCH_UPDATE: `${MCI_PREFIX}/search/results/update`,
    MCI_SEARCH_DETAILS: `${MCI_PREFIX}/search/details/(.)+`,
    MCI_HELP: `${MCI_PREFIX}/help`,
};

