import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable({
    providedIn: 'root'
})
export class ApplicationInsightsLogsService {

    appInsights: ApplicationInsights;
    constructor() { }

    initialize() {
        if (this.isConfigValid()) {
            this.appInsights = new ApplicationInsights({
                config: {
                    connectionString: environment.appInsights?.connectionString,
                    enableAutoRouteTracking: environment.appInsights.trackRouthChanges,
                    autoTrackPageVisitTime: environment.appInsights.autoTrackPageVisitTime
                }
            });
            this.appInsights.loadAppInsights();
        }
    }

    logPageView(name?: string, uri?: string) { // option to call manually
        this.appInsights.trackPageView({ name, uri });
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        this.appInsights.trackEvent({ name }, properties);
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        this.appInsights.trackMetric({ name, average }, properties);
    }

    logException(exception: Error, severityLevel?: number) {
        this.appInsights.trackException({ exception, severityLevel });
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        this.appInsights.trackTrace({ message }, properties);
    }

    private isConfigValid(): boolean {
        const appInsightsConfig: any = environment.appInsights || {};
        const isActive = appInsightsConfig.enable === true;
        const requiredKeys = ['trackRouthChanges', 'autoTrackPageVisitTime'];
        return isActive && requiredKeys.every(key => appInsightsConfig.hasOwnProperty(key));
    }
}
