import { Icons } from '@app/core/data-models/enums/icons.enum';
import { ReportsKey } from '@app/core/services/backend/interfaces/reports-response.interface';
import { PageLogoInterface } from '@shared/layout/main-layout/page-logo/page-logo.interface';


export enum ReportType {
    remediation = 1,
    household,          // HouseholdId access
    dataComparison,     // Best Indentity access
    activities
}

export const headerData: PageLogoInterface = {
    left: {
        title: 'Search',
        subTitle: 'Reports',
        icon: Icons.Search
    },
    right: {
        title: 'Generate',
        subTitle: 'Print',
        icon: Icons.Reports
    }
};

export interface IRemediationData {
    title: string;
    apiKey: string;
    data?: any;
}

export type DataByReport = {
    [key in ReportsKey]: IRemediationData[]
};
