import { HEADER_TITLES } from '@app/shared/layout/components/header-title/header-title.config';
import { ACL_PERMISSIONS_VALUE as ROUTES } from '@app/core/services';
import { BreadcrumbsStep } from '@app/core/services/breadcrumbs/breadcrumbs-step.interface';

export const COLLAPSE_COUNT = 5;

export const BREADCRUMB_HOME: BreadcrumbsStep = {
    label: HEADER_TITLES.titleMCIHome,
    route: ROUTES.MCI_SEARCH
};
