import { DatePipe } from "@angular/common";
import { formatSSN } from "@app/shared/utility/functions/formatting/format-ssn.function";
import { formatPhone } from "@app/shared/utility/functions/formatting/format-phone.function";
import { IMpiRecord } from "@services/backend/mci/search.config";
import { IBestDetails } from "../../search/details/search-details.config";


export enum CompareIndex {
    first = 0,
    second = 1
}

export enum NameKeys {
    firstName = 'firstName',
    middleName = 'middleName',
    lastName = 'lastName'
}

export enum titleKey {
    publicRecord = 'public',
    commonRecord = 'record'
}

export interface RecordMappingInterface {
    label: string;
    value?: string;
    key?: string;
    transform?: 'ssn' | 'date' | 'dateTime' | 'lower' | 'upper' | 'dob' | 'phone';
    showRow?: boolean;
    highlighted?: boolean;
}

export interface NameCompareRecord {
    firstName?: NameKeys.firstName | string;
    middleName?: NameKeys.middleName | string;
    lastName?: NameKeys.lastName | string;
    [key: string]: any;
}

export interface ResultCompareRecordsInterface {
    [CompareIndex.first]: RecordMappingInterface[],
    [CompareIndex.second]: RecordMappingInterface[]
}

export interface CompareRecordsDataInterface {
    records: IMpiRecord[] | IBestDetails[];
    isPublicRecord?: boolean;
}

export const title = {
    [titleKey.publicRecord]: 'COMPARE ALL PUBLIC RECORDS DATA',
    [titleKey.commonRecord]: 'COMPARE DATA MATCHES'
};

export const subTitle = {
    [titleKey.publicRecord]: 'Data variations are highlighted below.',
    [titleKey.commonRecord]: 'Application data differences are highlighted and pre-populated to the top.'
};

export const publicRecordMapping: RecordMappingInterface[] = [
    { key: 'dateOfBirth', label: 'DOB', transform: 'dob' },
    { key: 'suffix', label: 'Suffix', transform: 'upper' },
    { key: 'ssn', label: 'SSN', transform: 'ssn' },
    { key: 'addressLine1', label: 'Street Address 1' },
    { key: 'addressLine2', label: 'Street Address 2' },
    { key: 'cityName', label: 'City', transform: 'upper' },
    { key: 'state', label: 'State', transform: 'upper' },
    { key: 'zipCode', label: 'Zip' },
    { key: 'bestIdentityPhoneNumbers', label: 'Phone', transform: 'phone' },
    { key: 'bestIdentityEmailAddresses', label: 'Email', transform: 'lower' },
];

export const recordMapping: RecordMappingInterface[] = [
    ...publicRecordMapping,
    { key: 'phones.phoneNumber', label: 'Phone', transform: 'phone' },
    { key: 'emailAddress', label: 'Phone', transform: 'lower' },
    { key: 'firstName', label: 'First Name', transform: 'upper' },
    { key: 'middleName', label: 'Middle Name', transform: 'upper' },
    { key: 'lastName', label: 'Last Name', transform: 'upper' },
    { key: 'title', label: 'Title', transform: 'upper' },
    { key: 'dateAdded', label: 'Transaction Date', transform: 'dateTime' },
    { key: 'source', label: 'Source' },
    { key: 'nameTitle', label: 'Name Title', transform: 'upper' },
    { key: 'mpiId', label: 'MPI ID' }
];

export class FormatRecord {
    static ssn(value: string): string {
        return formatSSN(value || '');
    }

    static date(value: string): string {
        return new DatePipe('en_US').transform(value, 'MM/dd/y') || '';
    }

    static dateTime(value: string): string {
        return new DatePipe('en_US').transform(value, 'MM/dd/y hh:mm a') || '';
    }

    static upper(value: string): string {
        return (value || '').toUpperCase();
    }

    static lower(value: string): string {
        return (value || '').toLowerCase();
    }

    static dob(value: string): string {
        return `${value.slice(4, 6)}/${value.slice(6, 8)}/${value.slice(0, 4)}`;
    }

    static phone(value: string): string {
        return formatPhone(value);
    }
}
