import { IMpiRecord } from '@services/backend/mci/search.config';
import { Action, createReducer, on } from '@ngrx/store';
import {
    HistoryRecordsActions,
    HouseholdInsightsActions,
    ProfileBoosterActions,
    RecordsActions,
    setBestDetails,
    setMortalityInsights
} from '../actions/records.actions';
import * as fromSeatchDetails from '@app/containers/mci/search/details/search-details.config';

export interface ISplitData {
    transactions: IMpiRecord[];
    identity?: IMpiRecord;
}

export const recordsKey = 'records';

export interface RecordsState {
    search: IMpiRecord[];
    householdInsightsRecords: IMpiRecord[];
    historicalRecords: fromSeatchDetails.IHistoryTransaction[];
    currentRecords: IMpiRecord[];
    bestDetails: fromSeatchDetails.IBestDetails;
    mortalityInsights: fromSeatchDetails.IMortalityInsights;
    profileBooster: fromSeatchDetails.IListProfileBoosterAttributesByMpiId;
    idsSelectedSearchResult: number[];
    splitData: ISplitData;
    mergeIdentities: IMpiRecord[];
}

export const initialRecordState: RecordsState = {
    search: null,
    householdInsightsRecords: null,
    historicalRecords: null,
    currentRecords: null,
    bestDetails: null,
    mortalityInsights: null,
    profileBooster: {},
    idsSelectedSearchResult: [],
    splitData: null,
    mergeIdentities: null
};

export const recordsReducers = createReducer(
    initialRecordState,
    on(RecordsActions.setSearchRecords, (state, { records }) => ({ ...state, search: records })),
    on(HouseholdInsightsActions.setData, (state, { records }) => ({ ...state, householdInsightsRecords: records })),
    on(RecordsActions.selectedRecordInSearchResult, (state, { id }) => ({
        ...state, idsSelectedSearchResult: state.idsSelectedSearchResult.includes(id)
            ? state.idsSelectedSearchResult.filter(currentId => currentId !== id)
            : [...state.idsSelectedSearchResult, id]
    })),
    on(RecordsActions.clearSearchResult, (state) => ({ ...state, search: null })),
    on(RecordsActions.setSplitData, (state, { transactions, identity }) => ({ ...state, splitData: { transactions, identity } })),
    on(RecordsActions.clearSplitData, (state) => ({ ...state, splitData: null })),
    on(RecordsActions.setMergeData, (state, { identities }) => ({ ...state, mergeIdentities: identities })),
    on(RecordsActions.clearMergeData, (state) => ({ ...state, mergeIdentities: null })),
    on(RecordsActions.clearRecordsSelectedInSearchResult, (state) => ({ ...state, idsSelectedSearchResult: [] })),
    on(HistoryRecordsActions.setData, (state, { records }) => ({ ...state, historicalRecords: records })),
    on(HistoryRecordsActions.clearData, (state) => ({ ...state, historicalRecords: null })),
    on(RecordsActions.setTransactions, (state, { records }) => ({ ...state, currentRecords: records })),
    on(setBestDetails, (state, { data }) => ({ ...state, bestDetails: data })),
    on(setMortalityInsights, (state, { data }) => ({ ...state, mortalityInsights: data })),
    on(ProfileBoosterActions.setData, (state, { data }) => ({ ...state, profileBooster: { ...state.profileBooster, ...data } })),
    on(ProfileBoosterActions.clearData, (state) => ({ ...state, profileBooster: {} })),
    on(RecordsActions.clearIdentityDetails, (state) => ({
        ...state,
        householdInsightsRecords: null,
        historicalRecords: null,
        currentRecords: null,
        bestDetails: null,
        mortalityInsights: null,
        profileBooster: {}
    })),
);

export function recordsReducer(state: RecordsState | undefined, action: Action): RecordsState {
    return recordsReducers(state, action);
}
