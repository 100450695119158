/**
 * Transforms raw value into displayable text
 * @param phoneNumber
 *
 * Example:
 *  Provide 1234567890
 *  Returns (123)-456-7890
 */
export function formatPhone(phoneNumber: string) {
    if (phoneNumber.length !== 10) return phoneNumber;
    
    const onlyDigits = ('' + phoneNumber).replace(/\D/g, '');
    const m = onlyDigits.match(/^(\d{3})(\d{3})(\d{4})$/);
    return (!m) ? phoneNumber : '(' + m[1] + ') ' + m[2] + '-' + m[3];
}
