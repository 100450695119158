import { SortKey, UtilsService } from './utils.service';
import { Observable, combineLatest, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { BackendService } from '@services';
import { AppLog } from '@services/log/app-log.service';
import { HttpClient } from '@angular/common/http';
import { IMpiRecord, ISearchParam, ISearchResponse, MpiStorageKey } from './search.config';
import { map, catchError, switchMap, take, delay } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { formatPhone } from '@app/shared/utility/functions/formatting/format-phone.function';
import { MciCompareRecordsModalComponent } from '@app/containers/mci/shared/compare-records/compare-records-modal.component';
import { IListProfileBoosterAttributesByMpiId, IIdentityDetail, IHistoryTransaction, IBestDetails } from '@containers/mci/search/details/search-details.config';
import { RecordsActions, selectUserHasPermissionToBestIdentity, selectUserHasPermissionToMortalityInsights, selectUserHasPermissionToPB } from '@app/store';
import { Store } from '@ngrx/store';
import { State } from '@app/store/states';
import { mpiConfig } from '@app/core/config/app-config.constants';


@Injectable({
    providedIn: 'root'
})
export class SearchService extends BackendService {

    constructor(
        protected http: HttpClient,
        protected logger: AppLog,
        protected store: Store<State>,
        public dialog: MatDialog,
        private utilsService: UtilsService
    ) {
        super(http, logger, store);
    }

    /**
     * Get identity details by mpiId
     *
     * @param mpiId
     * @returns Observable<IIdentityDetail>
     */
    getIdentityDetails(mpiId: string): Observable<IIdentityDetail> {
        const userHasPermissionToPB$ = this.store.select(selectUserHasPermissionToPB).pipe(take(1));
        const userHasPermissionToBestDetails$ = this.store.select(selectUserHasPermissionToBestIdentity).pipe(take(1));
        const userHasPermissionToMortalityInsights$ = this.store.select(selectUserHasPermissionToMortalityInsights).pipe(take(1));

        return combineLatest([userHasPermissionToPB$, userHasPermissionToBestDetails$, userHasPermissionToMortalityInsights$]).pipe(
            switchMap(([userHasPermissionToPB, userHasPermissionToBestDetails, userHasPermissionToMortalityInsights]) =>
                this.doGet(mpiConfig.api.mciElementByMciId(mpiId)).pipe(
                    map(response => {
                        const data = response.data;
                        return {
                            ...data,
                            mpiRecords: this.utilsService.setPrincipalAddress(response.data.mpiRecords),
                            bestDetailsAvailable: (userHasPermissionToBestDetails === false) ? false : data.bestDetailsAvailable,
                            profileBoosterAttributesAvailable: (userHasPermissionToPB === false) ? false : data.profileBoosterAttributesAvailable,
                            mortalityInsightsAvailable: (userHasPermissionToMortalityInsights === false) ? false : data.mortalityInsightsAvailable
                        }
                    })
                )
            )
        );
    }

    /**
     * Get profile booster attributes by mpiIds
     *
     * @param mpiIds
     * @returns Observable<IListProfileBoosterAttributesByMpiId>
     */
    getProfileBoosterInBatch(mpiIds: string[]): Observable<IListProfileBoosterAttributesByMpiId> {
        return this.doPost(mpiConfig.api.mciProfileBoosterInBatch, JSON.stringify({ mpiIds }))
            .pipe(map(response => response.data));
    }

    /**
     * Get history Merge/Split records
     *
     * @param mpiId
     * @returns Observable<IHistoryTransaction[]>
     */
    getHistoryRecords(mpiId: string): Observable<IHistoryTransaction[]> {
        return this.doGet(mpiConfig.api.mciHistoryRecordsByMciRecordId(mpiId))
            .pipe(map(response => this.utilsService.sortByKey<IHistoryTransaction>(
                [...response.data], SortKey.dateAddedToHistoryRecords, false
            )));
    }

    /**
     * Get pending transactions
     *
     * @returns Observable<IMpiRecord[]>
     */
     getPendingTransactions(): Observable<IMpiRecord[]> {
        return this.doGet(mpiConfig.api.mciPendingTransactions)
            .pipe(map(response => response.data || []));
    }

    /**
     * Search for MCI
     *
     * @param params
     * @returns Observable<ISearchResponse>
     */
    search(params?: ISearchParam): Observable<IMpiRecord[]> {
        const payload = params
            ? JSON.stringify(params) : this.utilsService.getDataFromStorage(MpiStorageKey.lastSearchRequest);

        this.utilsService.setDataInStorage(MpiStorageKey.lastSearchRequest, payload);
        return this.doPost(mpiConfig.api.mciSearch, payload).pipe(
            map(response => this.utilsService.setPrincipalAddress(response.data || []))
        );
    }

    /**
     * Get records by householdId
     *
     * @param mpiHouseholdId
     * @returns Observable<IMpiRecord[]>
     */
    getRecordsByHouseholdId(mpiHouseholdId: string): Observable<IMpiRecord[]> {
        return this.doPost(mpiConfig.api.mciSearch, JSON.stringify({ mpiHouseholdId })).pipe(
            map(response => this.utilsService.setPrincipalAddress(response.data || []))
        );
    }

    /**
     * Merge elements by mpiIds
     *
     * @param mpiIds
     * @returns Observable<string>
     */
    merge(mpiIds: string[]): Observable<string> {
        const payload = JSON.stringify({ mpiIds });
        return this.doPost(mpiConfig.api.mciMerge, payload)
            .pipe(
                map(response => this.handleSuccessRespsonse(response) as string),
                catchError((error, obs) => this.handleErrorRersponse(error))
            );
    }

    /**
     * Split elements by mpiRecordIds
     *
     * @param toMpiId
     * @param sourceRids
     * @returns Observable<string>
     */
    split(identity: IMpiRecord, transactions: IMpiRecord[]): Observable<string> {
        const toMpiId = (identity.mpiId !== UtilsService.notAvailableValue) ? identity.mpiId : null;
        const sourceRids = transactions.map(transaction => transaction.sourceRid);
        const allRecords = !!toMpiId ? [identity, ...transactions] : transactions;
        const mpiIds = allRecords.map(record => record.mpiId);
        const payload = JSON.stringify({ toMpiId, sourceRids, mpiIds });
        return this.doPost(mpiConfig.api.mciSplit, payload)
            .pipe(
                map(response => this.handleSuccessRespsonse(response) as string),
                catchError((error, obs) => this.handleErrorRersponse(error))
            );
    }

    /**
     * Get main phone from phone list
     *
     * @param item
     * @returns string
     */
    getMainPhone(phones: any[]): string {
        if (!phones.length) {
            return '';
        }
        const phone = phones[0].phone || '';
        return formatPhone(phone);
    }

    /**
     * Open compare dialog
     *
     * @param records
     * @param isPublicRecord
     * @private
     */
    openCompareDialog(records: IMpiRecord[] | IBestDetails[], isPublicRecord: boolean = false): void {
        this.dialog.open(MciCompareRecordsModalComponent, {
            minWidth: '1024px',
            maxWidth: '75vw !important',
            width: '75vw',
            data: {
                isPublicRecord,
                records: records.map(record => this.mergeRecordZipcode(record))
            }
        });
    }

    /**
     * Merges the record's zipcode with the zip4 extension if it exists
     *
     * @param record - The record to merge, which can be of type IMpiRecord or IBestDetails
     * @returns The merged record with the updated zipcode
     */
    private mergeRecordZipcode<T extends IMpiRecord | IBestDetails>(record: T): T {
        const { address } = record;
        if (!Boolean(address?.zip4)) {
            return record;
        }
        return {
            ...record,
            address: {
                ...address,
                zipCode: `${address.zipCode}-${address.zip4}`
            }
        }
    }

    /**
     * Handle the success response
     *
     * @param response
     * @param clearResult
     * @returns IMpiRecord[] | string
     */
    private handleSuccessRespsonse(response: ISearchResponse): IMpiRecord[] | string {
        const data = response.data;
        this.store.dispatch(RecordsActions.clearSearchResult());
        return data;
    }

    /**
     * Handle an error response
     *
     * @param error the error of the request
     * @return Observable<never>
     */
    private handleErrorRersponse(error: any): Observable<never> {
        this.store.dispatch(RecordsActions.clearSearchResult());
        return throwError(() => new Error(error));
    }
}
