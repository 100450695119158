import { Observable, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { BackendService } from '@services';
import { AppLog } from '@services/log/app-log.service';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { State } from '@app/store/states';
import { mpiConfig } from '@app/core/config/app-config.constants';
import * as fromReportsInterfaces from '../interfaces/reports-response.interface';

@Injectable({
    providedIn: 'root'
})
export class ReportsService extends BackendService {

    constructor(
        protected http: HttpClient,
        protected logger: AppLog,
        protected store: Store<State>
    ) {
        super(http, logger, store);
    }

    /**
     * Get Activities Summary Reports
     *
     * @param startDate report start date
     * @param endDate report end date
     * @returns Observable<ActivitiesSummaryReportInterface>
     */
    getActivitiesSummaryReports(startDate: string, endDate: string): Observable<fromReportsInterfaces.ActivitiesSummaryReportInterface> {
        return this.getReportsByUrl(mpiConfig.api.activitiesSummaryReports, startDate, endDate)
            .pipe(map(response => response as fromReportsInterfaces.ActivitiesSummaryReportInterface));
    }

    /**
     * Get Remediation Reports
     *
     * @param startDate report start date
     * @param endDate report end date
     * @returns Observable<RemediationReportInterface>
     */
    getRemediationReports(startDate: string, endDate: string): Observable<fromReportsInterfaces.RemediationReportInterface> {
        return this.getReportsByUrl(mpiConfig.api.remediationReport, startDate, endDate)
            .pipe(map(response => response as fromReportsInterfaces.RemediationReportInterface));
    }

    /**
     * Get Household Reports
     *
     * @param startDate report start date
     * @param endDate report end date
     * @returns Observable<HouseholdReportInterface>
     */
    getHouseholdReports(startDate: string, endDate: string): Observable<fromReportsInterfaces.HouseholdReportInterface> {
        return this.getReportsByUrl(mpiConfig.api.householdIdReport, startDate, endDate)
            .pipe(map(response => response as fromReportsInterfaces.HouseholdReportInterface));
    }

    /**
     * Get Data Comparison Reports
     *
     * @param startDate report start date
     * @param endDate report end date
     * @returns Observable<DataComparisonInterface>
     */
    getDataComparisonReports(startDate: string, endDate: string): Observable<fromReportsInterfaces.DataComparisonInterface> {
        return this.getReportsByUrl(mpiConfig.api.dataComparisonReport, startDate, endDate)
            .pipe(map(response => response as fromReportsInterfaces.DataComparisonInterface));
    }

    private getReportsByUrl(url: string, startDate: string, endDate: string): Observable<fromReportsInterfaces.ApiResponseReportsTypes> {
        const payload = JSON.stringify({ startDate, endDate });
        return this.doPost(url, payload).pipe(map(response => response?.data));
    }
}
