import { ComplianceInterface } from '@app/containers/compliance/compliance.config';
import { selectAuthUserCompliances } from '@app/store/selectors/auth.selectors';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { State } from '@app/store/states';

@Injectable()
export class ComplianceInterceptor implements HttpInterceptor {

    constructor(
        private store: Store<State>,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.store.pipe(
            select(selectAuthUserCompliances),
            take(1),
            switchMap((compliances): Observable<HttpEvent<any>> => {
                if (!compliances) {
                    return next.handle(request);
                }
                request = this.setCompliances(compliances, request);
                return next.handle(request);
            })
        );
    }

    private setCompliances(compliances: ComplianceInterface, request: HttpRequest<any>): HttpRequest<any> {
        request = request.clone({
            headers: request.headers
                .set('dmf', compliances.dmf)
                .set('dppa', compliances.dppa)
                .set('glba', compliances.glba)
        });
        return request;
    }
}

