<button class="dialog-close" [mat-dialog-close]="false" aria-label="Close">
    <i class="fa fa-times" aria-hidden="true"></i>
</button>

<h2 mat-dialog-title>{{title}}</h2>

<mat-dialog-content>
    <i class="icon {{type}} fa {{icon}}" aria-hidden="true"></i>
    <p [innerHTML]="content | sanitizeHtml"></p>
</mat-dialog-content>

<mat-dialog-actions>
    @if (hasYes) {
        <button class="btn btn-primary" aria-label="Confirm" [mat-dialog-close]="true">{{yesLabel}}</button>
    }

    @if (hasNo) {
        <button class="btn btn-light" aria-label="Cancel" [mat-dialog-close]="false">{{noLabel}}</button>
    }
</mat-dialog-actions>