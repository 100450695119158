import { MESSAGE } from '@app/shared/utility/message';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, takeUntil, exhaustMap } from 'rxjs/operators';
import { LogPriority } from '@app/core/services/log/log-priority.enum';
import { of } from 'rxjs';
import { AppLog } from '@app/core/services/log/app-log.service';
import { WorklistService } from '@services/backend/mci/worklist.service';
import { MpiWorklistSection } from '@services/backend/mci/search.config';
import * as fromActions from '@app/store/actions';

@Injectable()
export class WorklistEffects {

    constructor(
        private actions$: Actions,
        private logger: AppLog,
        private worklistService: WorklistService
    ) {
    }

    addIdentities$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.WorklistActions.addIdentities),
        exhaustMap(({ identities }) =>
            this.worklistService.addItemsToWorklist(identities, MpiWorklistSection.identities).pipe(
                exhaustMap(success => {
                    if (success === true) {
                        return of(
                            fromActions.WorklistActions.setIdentities({ identities: this.worklistService.updateStatus([...identities]) }),
                            fromActions.RecordsActions.updateSearchRecordsStatus(),
                            fromActions.RecordsActions.clearRecordsSelectedInSearchResult(),
                            fromActions.WorklistActions.clearIdentityIdsSelected(),
                            fromActions.WorklistActions.clearTransactionIdsSelected()
                        );
                    } else {
                        return of(fromActions.showErrorMessage({
                            message: MESSAGE.ERROR_ADD_RECORDS_TO_WORKLIST,
                            error: { error: 'It was not possible to add the identities to the worklist' }
                        }));
                    }
                }),
                catchError((error) => {
                    return of(fromActions.showErrorMessage({ message: MESSAGE.ERROR_ADD_RECORDS_TO_WORKLIST, error }));
                })
            )
        )
    ));

    addTransactions$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.WorklistActions.addTransactions),
        exhaustMap(({ transactions }) =>
            this.worklistService.addItemsToWorklist(transactions, MpiWorklistSection.transactions).pipe(
                exhaustMap(success => {
                    if (success === true) {
                        return of(
                            fromActions.WorklistActions.setTransactions({ transactions: this.worklistService.updateStatus([...transactions]) }),
                            fromActions.RecordsActions.updateSearchRecordsStatus()
                        );
                    } else {
                        return of(fromActions.showErrorMessage({
                            message: MESSAGE.ERROR_ADD_RECORDS_TO_WORKLIST,
                            error: { error: 'It was not possible to add the transactions to the worklist' }
                        }));
                    }
                }),
                catchError((error) => {
                    return of(fromActions.showErrorMessage({ message: MESSAGE.ERROR_ADD_RECORDS_TO_WORKLIST, error }));
                })
            )
        )
    ));

    loadIdentities$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.WorklistActions.loadIdentities),
        exhaustMap(() => this.worklistService.getItemsFromScratchpad(MpiWorklistSection.identities).pipe(
            takeUntil(this.actions$.pipe(ofType(fromActions.cancelRequests))),
            map(records => fromActions.WorklistActions.loadIdentitiesSuccess({ records })),
            catchError(error => {
                this.logger.error('Error loading identities', { logPriority: LogPriority.HIGH }, error);
                return of(fromActions.WorklistActions.loadIdentitiesFail());
            })
        ))
    ));

    loadTransactions$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.WorklistActions.loadTransactions),
        exhaustMap(() => this.worklistService.getItemsFromScratchpad(MpiWorklistSection.transactions).pipe(
            takeUntil(this.actions$.pipe(ofType(fromActions.cancelRequests))),
            map(records => fromActions.WorklistActions.loadTransactionsSuccess({ records })),
            catchError(error => {
                this.logger.error('Error loading transactions', { logPriority: LogPriority.HIGH }, error);
                return of(fromActions.WorklistActions.loadTransactionsFail());
            })
        ))
    ));

    removeRecordFromWorklist$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.WorklistActions.removeRecord),
        exhaustMap(({ record, section }) => this.worklistService.delete(record.mpiRecordId, section).pipe(
            exhaustMap(success => {
                if (success) {
                    const mpiRecordId = record.mpiRecordId;
                    return [
                        ((section === MpiWorklistSection.identities)
                            ? fromActions.WorklistActions.removeOneIdentity({ mpiRecordId })
                            : fromActions.WorklistActions.removeOneTransaction({ mpiRecordId })
                        ),
                        fromActions.RecordsActions.updateSearchRecordsStatus()
                    ];
                } else {
                    return of(fromActions.showErrorMessage({
                        message: MESSAGE.ERROR_DELETE_RECORDS_TO_WORKLIST,
                        error: { error: 'Deleting a record from the work list failed' }
                    }));
                }
            }),
            catchError((error) => {
                return of(fromActions.showErrorMessage({ message: MESSAGE.ERROR_ADD_RECORDS_TO_WORKLIST, error }));
            })
        ))
    ));
}
