import { RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { AclService } from '@services/acl/acl.service';
import { AppLog } from '@app/core/services';
import { Observable } from 'rxjs';
import { LogPriority } from '@services/log/log-priority.enum';
import { State } from '@app/store/states';
import { Store } from '@ngrx/store';
import { selectIsAuth } from '@app/store/selectors';
import { map, first, switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';

export const AclGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store<State> = inject(Store),
    logger: AppLog = inject(AppLog),
    aclService: AclService = inject(AclService),
    location: Location = inject(Location)
): Observable<boolean> => {
    return store.select(selectIsAuth)
        .pipe(
            first(value => value === true),
            switchMap(() => aclService.hasRouteAccess(state.url).pipe(
                map(hasAccess => {
                    if (!hasAccess) {
                        logger.warning(
                            'User trying to access a restricted area ',
                            { logPriority: LogPriority.MEDIUM },
                            { url: state.url }
                        );
                        location.back();
                    }
                    return !!hasAccess;
                })
            ))
        );
}
