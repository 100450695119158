import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable, of, forkJoin } from 'rxjs';
import { map, shareReplay, catchError } from 'rxjs/operators';
import { AppLog } from '../log/app-log.service';
import { LogPriority } from '../log/log-priority.enum';

const packageJson = require('../../../../../package.json');

@Injectable({
    providedIn: 'root'
})
export class EnvLoaderService {

    private readonly configPath = 'assets/config/config.json';
    private readonly secretsPath = 'assets/config/secrets/secrets.json';

    constructor(
        private http: HttpClient,
        private logger: AppLog
    ) { }

    load(): Observable<boolean> {
        return forkJoin([this.getConfigs(), this.getSecrets()]).pipe(
            map(([config, secrets]) => {
                const dynamicEnv = {
                    ...config,
                    ...secrets,
                    logPriorityCutoff: LogPriority.MEDIUM,
                    appVersion: packageJson.version
                };
                Object.keys(dynamicEnv).forEach(key => {
                    environment[key] = dynamicEnv[key];
                });
                return true;
            }),
            catchError(() => of(false)
        ));
    }

    private getConfigs(): Observable<any> {
        return this.http.get(this.configPath, { responseType: 'json' }).pipe(
            shareReplay(1),
            catchError(error => {
                this.logger.error('Error loading config', { logPriority: LogPriority.HIGH }, error);
                return of({});
            })
        );
    }

    private getSecrets(): Observable<any> {
        // TMP
        return of({});
        // --

        return this.http.get(this.secretsPath, { responseType: 'json' }).pipe(
            shareReplay(1),
            catchError(error => {
                this.logger.error('Error loading secrets', { logPriority: LogPriority.HIGH }, error);
                return of({});
            })
        );
    }

}
