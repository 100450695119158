import { InjectionToken } from '@angular/core';
import { API_CONFIG, IApi } from './api-config';
import { AUTH_CONFIG, IAuthConfig } from './auth-config';
import { IAutoRenewSessionConfig, RENEW_SESSION_CONFIG } from '@app/core/config/auto-renew-session.config';
import { EXTERNAL_LINKS, IExternalLinks } from '@app/core/config/external-links-config';

export interface IAppConfig {
    api: IApi;
    auth: IAuthConfig;
    autoRenewSession: IAutoRenewSessionConfig;
    externalLinks: IExternalLinks;
}

export const mpiConfig: IAppConfig = {
    api: API_CONFIG,
    auth: AUTH_CONFIG,
    autoRenewSession: RENEW_SESSION_CONFIG,
    externalLinks: EXTERNAL_LINKS
};
