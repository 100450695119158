import { LogPriority } from '@services/log/log-priority.enum';

export interface AppInsightConfigInterface {
    connectionString: string;
    enable: boolean;
    trackRouthChanges?: boolean; // option to log all route changes
    autoTrackPageVisitTime?: boolean;
}

// Results in a slide out panel, opened with a floating icon in the bottom-right corner, or CTRL+ALT+A. Local scans are not sent to the WASP server and do not impact scan history.
export enum A11yCATInteractiveType {
    always = 'always',  // page will always be scanned locally, even if sent to the wasp server for remote scanning.
    never = 'never',    // page will not be scanned locally.
    auto = 'auto'       // page will be scanned locally if an api key is not configured. (default)
}

export interface IEnvironment {
    production: boolean;
    env: string;
    debug: boolean;
    disableLogin: boolean;
    baseModuleUrl: string | null;
    baseUrl: string;
    enableProductTour?: boolean;
    enableServiceWorker: boolean;
    appVersion?: string;
    logPriorityCutoff: number;      // Only send logs to backend if this priority is met
    captchaConfigurable?: boolean;
    enableFullStory: boolean;       // Analytics for user activity
    fullStoryId: string;
    appInsights: AppInsightConfigInterface;
    a11yCAT?: AllyCatConfigInterface;
}

export interface AllyCatConfigInterface {
    cdn: string;
    appName: string;
    appKey?: string;
    interactive?: A11yCATInteractiveType;
};

export const defaultEnvironmentValues = {
    production: false,
    debug: true,
    disableLogin: true,
    baseModuleUrl: null,
    baseUrl: '/api/',
    enableProductTour: false,
    enableServiceWorker: true,
    appVersion: require('../../package.json').version,
    logPriorityCutoff: LogPriority.DO_NOT_STORE,
    captchaConfigurable: true,
    enableFullStory: false,
    fullStoryId: 'Z6926',
    appInsights: {
        enable: false,
        connectionString: '',
        trackRouthChanges: false,
        autoTrackPageVisitTime: false,
    }
};
